import styled from 'styled-components';

export const Faq = styled.div`
  max-width: 1170px;
  margin: 82px auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1220px) {
    padding: 0 15px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 42px;
  }
  & > div:first-child {
    max-width: 45%;
    margin-top: 42px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    h3 {
      font-size: 42px;
      margin-bottom: 12px;
      line-height: 48px;
      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      a {
        color: #065fd4;
        font-weight: 500;
      }
    }
  }
  & > div:last-child {
    max-width: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (max-width: 768px) {
      max-width: 100%;
      margin-top: 32px;
    }
    & > div {
      width: 100%;
    }
  }
`;

export const Box = styled.div`
  background: #9c7561;
  padding: 18px 24px 10px;
  border-radius: 7px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media (max-width: 768px) {
    padding: 12px 34px 10px 18px;
  }
  height: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  svg {
    max-width: 25px;
    position: absolute;
    top: -1px;
    right: 14px;
    @media (max-width: 768px) {
      top: -9px;
    }
    transition: 0.3s ease-in-out;
    &.expanded {
      transform: rotate(180deg);
    }
  }
  h3 {
    color: #fff;
    user-select: none;
    @media (max-width: 768px) {
      font-size: 16px;
      font-weight: 500;
    }
  }
  p {
    font-size: 14px;
    color: #fff;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  }
  .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    @media (max-width: 768px) {
      margin-top: 4px;
      font-size: 12px;
    }
  }
  .answer.expanded {
    max-height: 200px; // Adjust based on the content size
  }
`;
