import { useSelector } from 'react-redux';
import CustomHeroBlock from '../CustomHeroBlock';
import MainBlock from '../MainBlock';
import * as S from './styles';

const Hero = () => {
  const isByHour = useSelector((state) => state.params.isByHour);
  return (
    <S.Hero id='book'>
      <img src='/img/overlay.png' alt='overlay' />
      <div>
        <div className='min_height'>
          {isByHour ? (
            <h1>Your reliable hourly car service</h1>
          ) : (
            <h1>Reliable, Budget-Friendly and Premium Milan Transfers</h1>
          )}
          <p>Ensure a comfortable ride by booking your transfer today.</p>
        </div>
        <MainBlock />
      </div>
      <CustomHeroBlock />
      <img src='/img/overlay.png' alt='overlay' className='reversed' />
    </S.Hero>
  );
};

export default Hero;
