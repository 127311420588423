import { useEffect, useState } from 'react';
import * as S from './styles';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { setData } from '../../slices/paramsSlice';
import Step2 from './Step2';
import { setAlert } from '../../slices/alertSlice';
import Step3 from './Step3';
import Step4 from './Step4';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import Step5 from './Step5';

const useCurrentWidth = () => {
  // Initialize state with current window width
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width to state
      setWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return width;
};

const Booking = () => {
  const [activeCar, setActiveCar] = useState('business');
  const [isBabySeat, setIsBabySeat] = useState(false);
  const [isExtraStop, setIsExtraStop] = useState(false);
  const [isOtherServices, setIsOtherServices] = useState(false);
  const [otherServicesText, setOtherServicesText] = useState('');

  const [activeCarReturn, setActiveCarReturn] = useState('business');
  const [isBabySeatReturn, setIsBabySeatReturn] = useState(false);
  const [isExtraStopReturn, setIsExtraStopReturn] = useState(false);
  const [isOtherServicesReturn, setIsOtherServicesReturn] = useState(false);
  const [otherServicesTextReturn, setOtherServicesTextReturn] = useState('');

  const data = useSelector((state) => state.params.data);
  const [inProgress, setInProgress] = useState(true);
  const [infoApi, setInfoApi] = useState({});
  const [infoApiReturn, setInfoApiReturn] = useState({});

  const [step, setStep] = useState(1);

  const [personalInfo, setPersonalInfo] = useState({});

  const isMoritz =
    data?.fromFull?.address?.toLowerCase().includes('moritz') ||
    data?.toFull?.address?.toLowerCase().includes('moritz');

  const isCervinia =
    data?.fromFull?.address?.toLowerCase().includes('cervinia') ||
    data?.toFull?.address?.toLowerCase().includes('cervinia');

  const isMoritzReturn =
    data?.fromFullReturn?.address?.toLowerCase().includes('moritz') ||
    data?.toFullReturn?.address?.toLowerCase().includes('moritz');

  const isCerviniaReturn =
    data?.fromFullReturn?.address?.toLowerCase().includes('cervinia') ||
    data?.toFullReturn?.address?.toLowerCase().includes('cervinia');

  const isFlight =
    data?.fromFull?.types?.[0] === 'airport' ||
    data?.fromFull?.types?.[0] === 'station' ||
    data?.fromFullReturn?.types?.[0] === 'airport' ||
    data?.fromFullReturn?.types?.[0] === 'station';

  const validateEmail = (val) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val);
  };

  const submitStep2 = () => {
    if (
      personalInfo?.first_name &&
      personalInfo?.last_name &&
      personalInfo.phone &&
      personalInfo?.email
    ) {
      if (validateEmail(personalInfo?.email)) {
        if (
          (isFlight &&
            personalInfo?.flight_number &&
            personalInfo?.meet_name) ||
          !isFlight
        ) {
          dispatch(
            setData({
              ...data,
              first_name: personalInfo?.first_name,
              last_name: personalInfo?.last_name,
              phone: personalInfo?.phone,
              email: personalInfo?.email,
              meet_name: personalInfo?.meet_name,
              isFlight: isFlight,
              flight_number: personalInfo?.flight_number,
              notes_chauffeur: personalInfo?.notes_chauffeur,
              other_notes: personalInfo?.other_notes,
            })
          );
          setStep(3);
        } else {
          dispatch(
            setAlert({ message: 'Please provide a flight or train number.' })
          );
        }
      } else {
        dispatch(
          setAlert({ message: 'Please provide a valid email address.' })
        );
      }
    } else {
      dispatch(setAlert({ message: 'Please complete all required fields.' }));
    }
  };

  const submitStep3 = () => {
    dispatch(setData({ ...data, confirmed: true }));
    setStep(4);
  };

  useEffect(() => {
    if (data?.persons > 3) {
      setActiveCar('van');
    }
  }, [data?.persons]);

  const history = useHistory();
  useEffect(() => {
    if (!data?.fromFull?.address) {
      history.push('/');
    }
  }, []);

  const priceAdjust = useSelector((state) => state.params.priceAdjust);

  const fetchOneDestin = async () => {
    console.log(data);
    const pickup = data?.fromFull?.address;
    const pickupId = data?.fromFull?.placeId;
    const pickupIata = data?.fromFull?.airportIata;
    const bookingRequestDate =
      data?.tab === 'with_return'
        ? moment(data?.range?.from).format('YYYY-MM-DD')
        : moment(data?.range).format('YYYY-MM-DD');
    const bookingRequestTime = `${
      dayjs(data?.time).hour() < 10
        ? `0${dayjs(data?.time).hour()}`
        : dayjs(data?.time).hour()
    }:${
      dayjs(data?.time).minute() < 10
        ? `0${dayjs(data?.time).minute()}`
        : dayjs(data?.time).minute()
    }`;
    const dropOff = data?.toFull?.address;
    const dropOffId = data?.toFull?.placeId;

    if (data?.tab === 'hour') {
      console.log(data?.hour * 60 * 60);
      const url = `https://www.blacklane.com/en/bookings/request/hourlies/service_class/?booking_request%5Bpickup%5D=${pickup}&booking_request%5Bpickup_place_id%5D=${pickupId}&booking_request%5Bpickup_airport_iata%5D=${pickupIata}&booking_request%5Bpickup_timezone%5D=Europe%2FRome&booking_request%5Bat_date%5D=${bookingRequestDate}&booking_request%5Bat_time%5D=${bookingRequestTime}&booking_request%5Bduration%5D=${
        data?.hour * 60 * 60
      }`;
      try {
        const response = await axios.get(
          `https://proxy-is-indeed-proxy.onrender.com/${url}`,
          {
            headers: {
              Accept: 'text/html', // Make sure the server knows you're expecting HTML
            },
          }
        );
        const html = response.data; // Assuming the response is text, not a stream
        const jsonMatch = html.match(
          /<script id="__NEXT_DATA__" type="application\/json">(.*?)<\/script>/s
        );
        if (jsonMatch && jsonMatch[1]) {
          const jsonData = JSON.parse(jsonMatch[1]);
          if (
            jsonData &&
            jsonData?.props?.pageProps?.stepData?.serviceClasses &&
            (jsonData?.props?.pageProps?.stepData?.serviceClasses?.[0]?.price
              ?.totalAmount ||
              jsonData?.props?.pageProps?.stepData?.serviceClasses?.[2]?.price
                ?.totalAmount ||
              jsonData?.props?.pageProps?.stepData?.serviceClasses?.[3]?.price
                ?.totalAmount)
          ) {
            const adjustmentFactor = 1 + priceAdjust / 100;

            // Iterate over each serviceClass and update the price if it exists
            jsonData.props.pageProps.stepData.serviceClasses.forEach(
              (serviceClass, index) => {
                // Check if the current index is 0 or 1
                if (index < 2) {
                  if (serviceClass?.price?.totalAmount) {
                    // Convert the original totalAmount to an integer before applying the adjustment
                    const originalPrice = parseInt(
                      serviceClass.price.totalAmount
                    );

                    // Apply the adjustment factor only if priceAdjust is not 0
                    if (priceAdjust !== 0) {
                      jsonData.props.pageProps.stepData.serviceClasses[
                        index
                      ].price.totalAmount = parseInt(
                        originalPrice * adjustmentFactor
                      );
                    } else {
                      // If priceAdjust is 0, just set the price to the rounded original
                      jsonData.props.pageProps.stepData.serviceClasses[
                        index
                      ].price.totalAmount = originalPrice;
                    }
                  }
                }
                // If index is 2 or higher, do nothing, leaving those service classes as they are
              }
            );
          }
          setInfoApi(jsonData);
          setInProgress(false);
        }
      } catch (error) {
        setInProgress(false);
        history.push({
          pathname: '/',
          state: { from: 'custom_request' },
        });
        dispatch(
          setAlert({
            message:
              'The selected destination is unavailable. Please fill out the custom request form.',
          })
        );
      }
    } else {
      const url = `https://www.blacklane.com/en/bookings/request/transfers/service_class/?booking_request%5Bpickup%5D=${pickup}&booking_request%5Bpickup_place_id%5D=${pickupId}&booking_request%5Bpickup_airport_iata%5D=${pickupIata}&booking_request%5Bpickup_timezone%5D=Europe%2FRome&booking_request%5Bat_date%5D=${bookingRequestDate}&booking_request%5Bat_time%5D=${bookingRequestTime}&booking_request%5Bduration%5D=&booking_request%5Bdropoff%5D=${dropOff}&booking_request%5Bdropoff_place_id%5D=${dropOffId}&booking_request%5Bdropoff_airport_iata%5D=&booking_request%5Bdropoff_timezone%5D=Europe%2FRome`;
      try {
        const response = await axios.get(
          `https://proxy-is-indeed-proxy.onrender.com/${url}`,
          {
            headers: {
              Accept: 'text/html', // Make sure the server knows you're expecting HTML
            },
          }
        );
        const html = response.data; // Assuming the response is text, not a stream
        const jsonMatch = html.match(
          /<script id="__NEXT_DATA__" type="application\/json">(.*?)<\/script>/s
        );
        if (jsonMatch && jsonMatch[1]) {
          const jsonData = JSON.parse(jsonMatch[1]);
          if (
            jsonData &&
            jsonData?.props?.pageProps?.stepData?.serviceClasses &&
            (jsonData?.props?.pageProps?.stepData?.serviceClasses?.[0]?.price
              ?.totalAmount ||
              jsonData?.props?.pageProps?.stepData?.serviceClasses?.[2]?.price
                ?.totalAmount ||
              jsonData?.props?.pageProps?.stepData?.serviceClasses?.[3]?.price
                ?.totalAmount)
          ) {
            const adjustmentFactor = 1 + priceAdjust / 100;

            // Iterate over each serviceClass and update the price if it exists
            jsonData.props.pageProps.stepData.serviceClasses.forEach(
              (serviceClass, index) => {
                // Check if the current index is 0 or 1
                if (index < 3) {
                  if (serviceClass?.price?.totalAmount) {
                    // Convert the original totalAmount to an integer before applying the adjustment
                    const originalPrice = parseInt(
                      serviceClass.price.totalAmount
                    );

                    // Apply the adjustment factor only if priceAdjust is not 0
                    if (priceAdjust !== 0) {
                      jsonData.props.pageProps.stepData.serviceClasses[
                        index
                      ].price.totalAmount = parseInt(
                        originalPrice * adjustmentFactor
                      );
                    } else {
                      // If priceAdjust is 0, just set the price to the rounded original
                      jsonData.props.pageProps.stepData.serviceClasses[
                        index
                      ].price.totalAmount = originalPrice;
                    }
                  }
                }
                // If index is 2 or higher, do nothing, leaving those service classes as they are
              }
            );
            // Set the modified jsonData to your state

            if (isMoritz) {
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/get_values?dbName=moritz`
                )
                .then((res) => {
                  setInfoApi((currentInfoApi) => {
                    // Create a new copy of the infoApi object
                    let newInfoApi = { ...jsonData };

                    // Check if serviceClasses exist
                    if (newInfoApi.props.pageProps.stepData.serviceClasses) {
                      // Update totalAmount for each service class
                      newInfoApi.props.pageProps.stepData.serviceClasses =
                        newInfoApi.props.pageProps.stepData.serviceClasses.map(
                          (serviceClass, index) => {
                            if (index === 0) {
                              // Business class
                              return {
                                ...serviceClass,
                                price: {
                                  ...serviceClass.price,
                                  totalAmount: res?.data.business * 100,
                                },
                              };
                            } else if (index === 2) {
                              // Van class
                              return {
                                ...serviceClass,
                                price: {
                                  ...serviceClass.price,
                                  totalAmount: res?.data.van * 100,
                                },
                              };
                            } else if (index === 3) {
                              // Sclass
                              return {
                                ...serviceClass,
                                price: {
                                  ...serviceClass.price,
                                  totalAmount: res?.data.sclass * 100,
                                },
                              };
                            }
                            return serviceClass; // Return unmodified if not one of the specified classes
                          }
                        );
                    }

                    // Return the modified infoApi object
                    return newInfoApi;
                  });
                  setInProgress(false);
                })
                .catch((err) => console.log(err));
            } else if (isCervinia) {
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/get_values?dbName=cervinia`
                )
                .then((res) => {
                  setInfoApi((currentInfoApi) => {
                    // Create a new copy of the infoApi object
                    let newInfoApi = { ...jsonData };

                    // Check if serviceClasses exist
                    if (newInfoApi.props.pageProps.stepData.serviceClasses) {
                      // Update totalAmount for each service class
                      newInfoApi.props.pageProps.stepData.serviceClasses =
                        newInfoApi.props.pageProps.stepData.serviceClasses.map(
                          (serviceClass, index) => {
                            if (index === 0) {
                              // Business class
                              return {
                                ...serviceClass,
                                price: {
                                  ...serviceClass.price,
                                  totalAmount: res?.data.business * 100,
                                },
                              };
                            } else if (index === 2) {
                              // Van class
                              return {
                                ...serviceClass,
                                price: {
                                  ...serviceClass.price,
                                  totalAmount: res?.data.van * 100,
                                },
                              };
                            } else if (index === 3) {
                              // Sclass
                              return {
                                ...serviceClass,
                                price: {
                                  ...serviceClass.price,
                                  totalAmount: res?.data.sclass * 100,
                                },
                              };
                            }
                            return serviceClass; // Return unmodified if not one of the specified classes
                          }
                        );
                    }

                    // Return the modified infoApi object
                    return newInfoApi;
                  });
                  setInProgress(false);
                })
                .catch((err) => console.log(err));
            } else {
              setInfoApi(jsonData);
              setInProgress(false);
            }
          } else {
            history.push({
              pathname: '/',
              state: { from: 'custom_request' },
            });
            dispatch(
              setAlert({
                message:
                  'The selected destination is unavailable. Please fill out the custom request form.',
              })
            );
          }
        } else {
          setInProgress(false);
          history.push({
            pathname: '/',
            state: { from: 'custom_request' },
          });
          dispatch(
            setAlert({
              message:
                'The selected destination is unavailable. Please fill out the custom request form.',
            })
          );
        }
      } catch (error) {
        setInProgress(false);
        history.push({
          pathname: '/',
          state: { from: 'custom_request' },
        });
        dispatch(
          setAlert({
            message:
              'The selected destination is unavailable. Please fill out the custom request form.',
          })
        );
      }
    }
  };

  const fetchOneReturn = async () => {
    const pickup = data?.fromFullReturn?.address;
    const pickupId = data?.fromFullReturn?.placeId;
    const pickupIata = data?.fromFullReturn?.airportIata;
    const bookingRequestDate = moment(data?.range?.to).format('YYYY-MM-DD');
    const bookingRequestTime = `${
      dayjs(data?.timeReturn).hour() < 10
        ? `0${dayjs(data?.timeReturn).hour()}`
        : dayjs(data?.timeReturn).hour()
    }:${
      dayjs(data?.timeReturn).minute() < 10
        ? `0${dayjs(data?.timeReturn).minute()}`
        : dayjs(data?.timeReturn).minute()
    }`;
    const dropOff = data?.toFullReturn?.address;
    const dropOffId = data?.toFullReturn?.placeId;

    const url = `https://www.blacklane.com/en/bookings/request/transfers/service_class/?booking_request%5Bpickup%5D=${pickup}&booking_request%5Bpickup_place_id%5D=${pickupId}&booking_request%5Bpickup_airport_iata%5D=${pickupIata}&booking_request%5Bpickup_timezone%5D=Europe%2FRome&booking_request%5Bat_date%5D=${bookingRequestDate}&booking_request%5Bat_time%5D=${bookingRequestTime}&booking_request%5Bduration%5D=&booking_request%5Bdropoff%5D=${dropOff}&booking_request%5Bdropoff_place_id%5D=${dropOffId}&booking_request%5Bdropoff_airport_iata%5D=&booking_request%5Bdropoff_timezone%5D=Europe%2FRome`;
    try {
      const response = await axios.get(
        `https://proxy-is-indeed-proxy.onrender.com/${url}`,
        {
          headers: {
            Accept: 'text/html', // Make sure the server knows you're expecting HTML
          },
        }
      );
      const html = response.data; // Assuming the response is text, not a stream
      const jsonMatch = html.match(
        /<script id="__NEXT_DATA__" type="application\/json">(.*?)<\/script>/s
      );
      if (jsonMatch && jsonMatch[1]) {
        const jsonData = JSON.parse(jsonMatch[1]);
        const adjustmentFactor = 1 + priceAdjust / 100;

        // Iterate over each serviceClass and update the price if it exists
        jsonData.props.pageProps.stepData.serviceClasses.forEach(
          (serviceClass, index) => {
            // Check if the current index is 0 or 1
            if (index < 3) {
              if (serviceClass?.price?.totalAmount) {
                // Convert the original totalAmount to an integer before applying the adjustment
                const originalPrice = parseInt(serviceClass.price.totalAmount);

                // Apply the adjustment factor only if priceAdjust is not 0
                if (priceAdjust !== 0) {
                  jsonData.props.pageProps.stepData.serviceClasses[
                    index
                  ].price.totalAmount = parseInt(
                    originalPrice * adjustmentFactor
                  );
                } else {
                  // If priceAdjust is 0, just set the price to the rounded original
                  jsonData.props.pageProps.stepData.serviceClasses[
                    index
                  ].price.totalAmount = originalPrice;
                }
              }
            }
            // If index is 2 or higher, do nothing, leaving those service classes as they are
          }
        );
        if (isMoritzReturn) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/get_values?dbName=moritz`)
            .then((res) => {
              setInfoApiReturn((currentInfoApi) => {
                // Create a new copy of the infoApi object
                let newInfoApi = { ...jsonData };

                // Check if serviceClasses exist
                if (newInfoApi.props.pageProps.stepData.serviceClasses) {
                  // Update totalAmount for each service class
                  newInfoApi.props.pageProps.stepData.serviceClasses =
                    newInfoApi.props.pageProps.stepData.serviceClasses.map(
                      (serviceClass, index) => {
                        if (index === 0) {
                          // Business class
                          return {
                            ...serviceClass,
                            price: {
                              ...serviceClass.price,
                              totalAmount: res?.data.business * 100,
                            },
                          };
                        } else if (index === 2) {
                          // Van class
                          return {
                            ...serviceClass,
                            price: {
                              ...serviceClass.price,
                              totalAmount: res?.data.van * 100,
                            },
                          };
                        } else if (index === 3) {
                          // Sclass
                          return {
                            ...serviceClass,
                            price: {
                              ...serviceClass.price,
                              totalAmount: res?.data.sclass * 100,
                            },
                          };
                        }
                        return serviceClass; // Return unmodified if not one of the specified classes
                      }
                    );
                }

                // Return the modified infoApi object
                return newInfoApi;
              });
              setInProgress(false);
            })
            .catch((err) => console.log(err));
        } else if (isCerviniaReturn) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/get_values?dbName=cervinia`)
            .then((res) => {
              setInfoApiReturn((currentInfoApi) => {
                // Create a new copy of the infoApi object
                let newInfoApi = { ...jsonData };

                // Check if serviceClasses exist
                if (newInfoApi.props.pageProps.stepData.serviceClasses) {
                  // Update totalAmount for each service class
                  newInfoApi.props.pageProps.stepData.serviceClasses =
                    newInfoApi.props.pageProps.stepData.serviceClasses.map(
                      (serviceClass, index) => {
                        if (index === 0) {
                          // Business class
                          return {
                            ...serviceClass,
                            price: {
                              ...serviceClass.price,
                              totalAmount: res?.data.business * 100,
                            },
                          };
                        } else if (index === 2) {
                          // Van class
                          return {
                            ...serviceClass,
                            price: {
                              ...serviceClass.price,
                              totalAmount: res?.data.van * 100,
                            },
                          };
                        } else if (index === 3) {
                          // Sclass
                          return {
                            ...serviceClass,
                            price: {
                              ...serviceClass.price,
                              totalAmount: res?.data.sclass * 100,
                            },
                          };
                        }
                        return serviceClass; // Return unmodified if not one of the specified classes
                      }
                    );
                }

                // Return the modified infoApi object
                return newInfoApi;
              });
              setInProgress(false);
            })
            .catch((err) => console.log(err));
        } else {
          // Set the modified jsonData to your state
          setInfoApiReturn(jsonData);
          setInProgress(false);
        }
      } else {
        setInProgress(false);
      }
    } catch (error) {
      setInProgress(false);
    }
  };

  useEffect(() => {
    fetchOneDestin();
    if (data?.tab === 'with_return') {
      fetchOneReturn();
    }
  }, []);

  function formatAmount(amount, decimalSeparator) {
    if (amount) {
      let amountStr = amount.toString();
      if (amountStr.length <= decimalSeparator) {
        amountStr =
          '0'.repeat(decimalSeparator - amountStr.length + 1) + amountStr;
      }
      const index = amountStr.length - decimalSeparator;
      const formattedAmount =
        amountStr.substring(0, index) + '.' + amountStr.substring(index);
      return formattedAmount;
    }
  }
  const dispatch = useDispatch();
  const submit = () => {
    const priceIntern =
      data?.tab === 'with_return'
        ? (
            parseFloat(price) +
            (isBabySeat ? 15 : 0) +
            (isExtraStop ? 20 : 0) +
            (isOtherServices ? 20 : 0) +
            (parseFloat(priceReturn) +
              (isBabySeatReturn ? 15 : 0) +
              (isExtraStopReturn ? 20 : 0) +
              (isOtherServicesReturn ? 20 : 0))
          ).toFixed(2)
        : (
            parseFloat(price) +
            (isBabySeat ? 15 : 0) +
            (isExtraStop ? 20 : 0) +
            (isOtherServices ? 20 : 0)
          ).toFixed(2);

    dispatch(
      setData({
        ...data,
        price: priceIntern,
        typeCar: activeCar,
        typeCarReturn: activeCarReturn,
        isBabySeat: isBabySeat,
        isExtraStop: isExtraStop,
        isOtherServices: isOtherServices,
        otherServicesText: otherServicesText,
        with_return: data?.tab === 'with_return',
        isBabySeatReturn: isBabySeatReturn,
        isExtraStopReturn: isExtraStopReturn,
        isOtherServicesReturn: isOtherServicesReturn,
        otherServicesTextReturn: otherServicesTextReturn,
        priceTo: price,
        priceFrom: priceReturn,
      })
    );
    setStep(2);
  };

  const [activeTabWithReturn, setActiveTabWithReturn] = useState('initial');

  const price =
    activeCar === 'business'
      ? formatAmount(
          infoApi?.props?.pageProps?.stepData?.serviceClasses?.[0]?.price
            ?.totalAmount,
          infoApi?.props?.pageProps?.stepData?.serviceClasses?.[0]?.price
            ?.decimalSeparator
        )
      : activeCar === 'van'
      ? formatAmount(
          infoApi?.props?.pageProps?.stepData?.serviceClasses?.[2]?.price
            ?.totalAmount,
          infoApi?.props?.pageProps?.stepData?.serviceClasses?.[2]?.price
            ?.decimalSeparator
        )
      : activeCar === 'sclass' &&
        formatAmount(
          infoApi?.props?.pageProps?.stepData?.serviceClasses?.[3]?.price
            ?.totalAmount,
          infoApi?.props?.pageProps?.stepData?.serviceClasses?.[3]?.price
            ?.decimalSeparator
        );

  const priceReturn =
    activeCarReturn === 'business'
      ? formatAmount(
          infoApiReturn?.props?.pageProps?.stepData?.serviceClasses?.[0]?.price
            ?.totalAmount,
          infoApiReturn?.props?.pageProps?.stepData?.serviceClasses?.[0]?.price
            ?.decimalSeparator
        )
      : activeCarReturn === 'van'
      ? formatAmount(
          infoApiReturn?.props?.pageProps?.stepData?.serviceClasses?.[2]?.price
            ?.totalAmount,
          infoApiReturn?.props?.pageProps?.stepData?.serviceClasses?.[2]?.price
            ?.decimalSeparator
        )
      : activeCarReturn === 'sclass' &&
        formatAmount(
          infoApiReturn?.props?.pageProps?.stepData?.serviceClasses?.[3]?.price
            ?.totalAmount,
          infoApiReturn?.props?.pageProps?.stepData?.serviceClasses?.[3]?.price
            ?.decimalSeparator
        );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', // This enables the smooth scrolling effect
    });
  }, [step]);

  const width = useCurrentWidth();

  console.log(data?.tab);

  return (
    <>
      {inProgress ? (
        <S.Loader>
          <CircularProgress />
        </S.Loader>
      ) : (
        <>
          <S.Booking>
            <div className='steps'>
              <p
                className={step === 1 ? 'active' : step > 1 ? 'completed' : ''}>
                {width > 768 ? 'Car Selection' : 1}
              </p>
              <p
                className={step === 2 ? 'active' : step > 2 ? 'completed' : ''}>
                {width > 768 ? 'Personal Information' : 2}
              </p>
              <p
                className={step === 3 ? 'active' : step > 3 ? 'completed' : ''}>
                {width > 768 ? 'Review Order' : 3}
              </p>
              <p
                className={step === 4 ? 'active' : step > 4 ? 'completed' : ''}>
                {width > 768 ? 'Payment' : 4}
              </p>
              <p
                className={step === 5 ? 'active' : step > 5 ? 'completed' : ''}>
                {width > 768 ? 'Confirmation' : 5}
              </p>
            </div>
            {step === 5 ? (
              <Step5 setStep={setStep} />
            ) : step === 1 ? (
              <>
                {data?.tab === 'with_return' && (
                  <S.WithReturn>
                    <div
                      className={
                        activeTabWithReturn === 'initial' ? 'active' : ''
                      }
                      onClick={() => setActiveTabWithReturn('initial')}>
                      Initial Transfer
                    </div>
                    <div
                      className={
                        activeTabWithReturn === 'return' ? 'active' : ''
                      }
                      onClick={() => setActiveTabWithReturn('return')}>
                      Return Transfer
                    </div>
                  </S.WithReturn>
                )}
                {data?.tab !== 'with_return' ||
                (data?.tab === 'with_return' &&
                  activeTabWithReturn === 'initial') ? (
                  <>
                    {data && data?.fromFull && (
                      <div className='main_info'>
                        <div>
                          <h3>
                            {data?.fromFull?.types?.[0] === 'airport' ? (
                              <svg
                                fill='#61473a'
                                height='25px'
                                width='64px'
                                version='1.1'
                                id='Layer_1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                viewBox='0 0 122.88 122.88'
                                xmlSpace='preserve'
                                className='via'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <style
                                    type='text/css'
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                    }}
                                  />{' '}
                                  <g>
                                    {' '}
                                    <path
                                      className='st0'
                                      d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                    />{' '}
                                  </g>{' '}
                                </g>
                              </svg>
                            ) : data?.fromFull?.types?.[0] === 'hotel' ? (
                              <svg
                                fill='#000000'
                                width='64px'
                                height='25px'
                                viewBox='0 0 24 24'
                                id='hotel-3'
                                data-name='Line Color'
                                xmlns='http://www.w3.org/2000/svg'
                                className='icon line-color'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  <rect
                                    id='secondary'
                                    x={12}
                                    y={15}
                                    width={4}
                                    height={6}
                                    style={{
                                      fill: 'none',
                                      stroke: '#61473a',
                                      strokeLinecap: 'round',
                                      strokeLinejoin: 'round',
                                      strokeWidth: 2,
                                    }}
                                  />
                                  <path
                                    id='primary'
                                    d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                    style={{
                                      fill: 'none',
                                      stroke: '#61473a',
                                      strokeLinecap: 'round',
                                      strokeLinejoin: 'round',
                                      strokeWidth: 2,
                                    }}
                                  />
                                </g>
                              </svg>
                            ) : data?.fromFull.types?.[0] === 'station' ? (
                              <svg
                                width='64px'
                                height='25px'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <path
                                    d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                    stroke='#61473a'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />{' '}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                width='64px'
                                height='25px'
                                viewBox='-3 0 20 20'
                                version='1.1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                fill='#000000'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <title>pin_sharp_circle [#624]</title>{' '}
                                  <desc>Created with Sketch.</desc>{' '}
                                  <defs> </defs>{' '}
                                  <g
                                    id='Page-1'
                                    stroke='none'
                                    strokeWidth={1}
                                    fill='none'
                                    fillRule='evenodd'>
                                    {' '}
                                    <g
                                      id='Dribbble-Light-Preview'
                                      transform='translate(-223.000000, -5439.000000)'
                                      fill='#61473a'>
                                      {' '}
                                      <g
                                        id='icons'
                                        transform='translate(56.000000, 160.000000)'>
                                        {' '}
                                        <path
                                          d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                          id='pin_sharp_circle-[#624]'>
                                          {' '}
                                        </path>{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>{' '}
                                </g>
                              </svg>
                            )}
                            {data?.fromFull?.address?.split(',')[0]}
                          </h3>
                          <p>
                            Pick up on {moment(data?.range).format('LL')},{' '}
                            {`${
                              dayjs(data?.time).hour() < 10
                                ? `0${dayjs(data?.time).hour()}`
                                : dayjs(data?.time).hour()
                            }:${
                              dayjs(data?.time).minute() < 10
                                ? `0${dayjs(data?.time).minute()}`
                                : dayjs(data?.time).minute()
                            }`}{' '}
                            (GMT+1)
                          </p>
                          {data?.tab === 'hour' ? (
                            <>
                              <p className='hour_little'>
                                {data?.hour} Hours <span></span> incl.{' '}
                                {data?.hour * 20} km
                              </p>
                            </>
                          ) : (
                            <>
                              <h3>
                                {data?.toFull?.types?.[0] === 'airport' ? (
                                  <svg
                                    fill='#61473a'
                                    height='25px'
                                    width='64px'
                                    version='1.1'
                                    id='Layer_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    viewBox='0 0 122.88 122.88'
                                    xmlSpace='preserve'
                                    className='via'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <style
                                        type='text/css'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                        }}
                                      />{' '}
                                      <g>
                                        {' '}
                                        <path
                                          className='st0'
                                          d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                        />{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                ) : data?.toFull?.types?.[0] === 'hotel' ? (
                                  <svg
                                    fill='#000000'
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    id='hotel-3'
                                    data-name='Line Color'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='icon line-color'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      <rect
                                        id='secondary'
                                        x={12}
                                        y={15}
                                        width={4}
                                        height={6}
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                      <path
                                        id='primary'
                                        d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                    </g>
                                  </svg>
                                ) : data?.toFull.types?.[0] === 'station' ? (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <path
                                        d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                        stroke='#61473a'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />{' '}
                                    </g>
                                  </svg>
                                ) : (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='-3 0 20 20'
                                    version='1.1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    fill='#000000'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <title>
                                        pin_sharp_circle [#624]
                                      </title>{' '}
                                      <desc>Created with Sketch.</desc>{' '}
                                      <defs> </defs>{' '}
                                      <g
                                        id='Page-1'
                                        stroke='none'
                                        strokeWidth={1}
                                        fill='none'
                                        fillRule='evenodd'>
                                        {' '}
                                        <g
                                          id='Dribbble-Light-Preview'
                                          transform='translate(-223.000000, -5439.000000)'
                                          fill='#61473a'>
                                          {' '}
                                          <g
                                            id='icons'
                                            transform='translate(56.000000, 160.000000)'>
                                            {' '}
                                            <path
                                              d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                              id='pin_sharp_circle-[#624]'>
                                              {' '}
                                            </path>{' '}
                                          </g>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                )}
                                {data?.toFull?.address?.split(',')[0]}{' '}
                                <svg
                                  width='64px'
                                  height='64px'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='arrow'>
                                  <g
                                    id='SVGRepo_bgCarrier'
                                    stroke-width='0'></g>
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'></g>
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <path
                                      d='M12 20L18 14M12 20L6 14M12 20L12 9.5M12 4V6.5'
                                      stroke='#64666b'
                                      stroke-width='1.5'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'></path>{' '}
                                  </g>
                                </svg>
                              </h3>
                              <p>{data?.toFull?.address}</p>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className='car_class'>
                      <h1>Select Car</h1>
                      <p>All prices include VAT, fees, and tolls</p>
                      <div>
                        {data?.persons <= 3 && (
                          <div
                            className={activeCar === 'business' ? 'active' : ''}
                            onClick={() => setActiveCar('business')}>
                            <img src='/img/eclass.png' alt='Business' />
                            <div>
                              <h3>Business Class</h3>
                              <div className='icons'>
                                <p>
                                  <img src='/img/users.svg' alt='Luggage' />3
                                </p>
                                <p>
                                  <img src='/img/luggage.svg' alt='Luggage' />2
                                </p>
                              </div>
                              <p>Mercedes-Benz E-Class</p>
                              <p className='small'>Most popular</p>
                            </div>
                            <p className='price'>
                              €
                              {formatAmount(
                                infoApi?.props?.pageProps?.stepData
                                  ?.serviceClasses?.[0]?.price?.totalAmount,
                                infoApi?.props?.pageProps?.stepData
                                  ?.serviceClasses?.[0]?.price?.decimalSeparator
                              )}
                            </p>
                          </div>
                        )}
                        <div
                          className={`${activeCar === 'van' ? 'active' : ''} ${
                            data?.persons > 3 ? 'solo' : ''
                          }`}
                          onClick={() => setActiveCar('van')}
                          style={{ borderTop: 'none' }}>
                          <img src='/img/vclass.webp' alt='Business' />
                          <div>
                            <h3>Business Van/SUV</h3>
                            <div className='icons'>
                              <p>
                                <img src='/img/users.svg' alt='Luggage' />7
                              </p>
                              <p>
                                <img src='/img/luggage.svg' alt='Luggage' />6
                              </p>
                            </div>
                            <p>Mercedes-Benz V-Class</p>
                            <p className='small'>Most spacious</p>
                          </div>
                          <p className='price'>
                            €
                            {formatAmount(
                              infoApi?.props?.pageProps?.stepData
                                ?.serviceClasses?.[2]?.price?.totalAmount,
                              infoApi?.props?.pageProps?.stepData
                                ?.serviceClasses?.[2]?.price?.decimalSeparator
                            )}
                          </p>
                        </div>
                        {data?.persons <= 3 &&
                          infoApi?.props?.pageProps?.stepData
                            ?.serviceClasses?.[3]?.price?.totalAmount && (
                            <div
                              className={activeCar === 'sclass' ? 'active' : ''}
                              onClick={() => setActiveCar('sclass')}
                              style={{ borderTop: 'none' }}>
                              <img src='/img/sclass.webp' alt='Business' />
                              <div>
                                <h3>First Class</h3>
                                <div className='icons'>
                                  <p>
                                    <img src='/img/users.svg' alt='Luggage' />3
                                  </p>
                                  <p>
                                    <img src='/img/luggage.svg' alt='Luggage' />
                                    2
                                  </p>
                                </div>
                                <p>Mercedes-Benz S-Class</p>
                                <p className='small'>Most luxurious</p>
                              </div>
                              <p className='price'>
                                €
                                {formatAmount(
                                  infoApi?.props?.pageProps?.stepData
                                    ?.serviceClasses?.[3]?.price?.totalAmount,
                                  infoApi?.props?.pageProps?.stepData
                                    ?.serviceClasses?.[3]?.price
                                    ?.decimalSeparator
                                )}
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='add-ons'>
                      <h2>Add-ons</h2>
                      <p>
                        If you need additional services, just tick the boxes or
                        let us know in the field below
                      </p>
                      <FormControlLabel
                        control={<Checkbox />}
                        label='Baby Seat (€15)'
                        checked={isBabySeat}
                        onChange={() => setIsBabySeat(!isBabySeat)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label='Extra Stop (€20)'
                        checked={isExtraStop}
                        onChange={() => setIsExtraStop(!isExtraStop)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label='Other Services (€20)'
                        checked={isOtherServices}
                        onChange={() => setIsOtherServices(!isOtherServices)}
                      />
                      <h4>Other Services</h4>
                      <textarea
                        placeholder='Type here...'
                        value={otherServicesText}
                        onChange={(e) => setOtherServicesText(e.target.value)}
                      />
                    </div>
                    <div className='info'>
                      <div>
                        <p>All classes include:</p>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>
                            Free cancellation up until 24 hours before pickup
                          </p>
                        </div>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>Free 60 minutes of wait time</p>
                        </div>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>Meet & Greet</p>
                        </div>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>Complimentary bottle of water</p>
                        </div>
                      </div>
                      <div>
                        <p>Please note:</p>
                        <div style={{ marginBottom: '24px' }}>
                          <img
                            src='/img/warning.svg'
                            alt='Circle'
                            style={{ marginTop: '2px' }}
                          />
                          <p>
                            Guest/luggage capacities must be abided by for
                            safety reasons. If you are unsure, select a larger
                            class as chauffeurs may turn down service when they
                            are exceeded.
                          </p>
                        </div>
                        <div>
                          <img
                            src='/img/warning.svg'
                            alt='Circle'
                            style={{ marginTop: '2px' }}
                          />
                          <p>
                            The vehicle images above are examples. You may get a
                            different vehicle of similar quality.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {data && data?.fromFull && (
                      <div className='main_info'>
                        <div>
                          <h3>
                            {data?.fromFullReturn?.types?.[0] === 'airport' ? (
                              <svg
                                fill='#61473a'
                                height='25px'
                                width='64px'
                                version='1.1'
                                id='Layer_1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                viewBox='0 0 122.88 122.88'
                                xmlSpace='preserve'
                                className='via'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <style
                                    type='text/css'
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                    }}
                                  />{' '}
                                  <g>
                                    {' '}
                                    <path
                                      className='st0'
                                      d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                    />{' '}
                                  </g>{' '}
                                </g>
                              </svg>
                            ) : data?.fromFullReturn?.types?.[0] === 'hotel' ? (
                              <svg
                                fill='#000000'
                                width='64px'
                                height='25px'
                                viewBox='0 0 24 24'
                                id='hotel-3'
                                data-name='Line Color'
                                xmlns='http://www.w3.org/2000/svg'
                                className='icon line-color'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  <rect
                                    id='secondary'
                                    x={12}
                                    y={15}
                                    width={4}
                                    height={6}
                                    style={{
                                      fill: 'none',
                                      stroke: '#61473a',
                                      strokeLinecap: 'round',
                                      strokeLinejoin: 'round',
                                      strokeWidth: 2,
                                    }}
                                  />
                                  <path
                                    id='primary'
                                    d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                    style={{
                                      fill: 'none',
                                      stroke: '#61473a',
                                      strokeLinecap: 'round',
                                      strokeLinejoin: 'round',
                                      strokeWidth: 2,
                                    }}
                                  />
                                </g>
                              </svg>
                            ) : data?.fromFullReturn.types?.[0] ===
                              'station' ? (
                              <svg
                                width='64px'
                                height='25px'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <path
                                    d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                    stroke='#61473a'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />{' '}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                width='64px'
                                height='25px'
                                viewBox='-3 0 20 20'
                                version='1.1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                fill='#000000'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <title>pin_sharp_circle [#624]</title>{' '}
                                  <desc>Created with Sketch.</desc>{' '}
                                  <defs> </defs>{' '}
                                  <g
                                    id='Page-1'
                                    stroke='none'
                                    strokeWidth={1}
                                    fill='none'
                                    fillRule='evenodd'>
                                    {' '}
                                    <g
                                      id='Dribbble-Light-Preview'
                                      transform='translate(-223.000000, -5439.000000)'
                                      fill='#61473a'>
                                      {' '}
                                      <g
                                        id='icons'
                                        transform='translate(56.000000, 160.000000)'>
                                        {' '}
                                        <path
                                          d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                          id='pin_sharp_circle-[#624]'>
                                          {' '}
                                        </path>{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>{' '}
                                </g>
                              </svg>
                            )}
                            {data?.fromFullReturn?.address?.split(',')[0]}
                          </h3>
                          <p>
                            Pick up on {moment(data?.range?.to).format('LL')},{' '}
                            {`${
                              dayjs(data?.timeReturn).hour() < 10
                                ? `0${dayjs(data?.timeReturn).hour()}`
                                : dayjs(data?.timeReturn).hour()
                            }:${
                              dayjs(data?.timeReturn).minute() < 10
                                ? `0${dayjs(data?.timeReturn).minute()}`
                                : dayjs(data?.timeReturn).minute()
                            }`}{' '}
                            (GMT+1)
                          </p>
                          {data?.tab === 'hour' ? (
                            <>
                              <h3>
                                {data?.hour} Hours <span></span> incl.{' '}
                                {data?.hour * 20} km
                              </h3>
                            </>
                          ) : (
                            <>
                              <h3>
                                {data?.toFullReturn?.types?.[0] ===
                                'airport' ? (
                                  <svg
                                    fill='#61473a'
                                    height='25px'
                                    width='64px'
                                    version='1.1'
                                    id='Layer_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    viewBox='0 0 122.88 122.88'
                                    xmlSpace='preserve'
                                    className='via'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <style
                                        type='text/css'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                        }}
                                      />{' '}
                                      <g>
                                        {' '}
                                        <path
                                          className='st0'
                                          d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                        />{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                ) : data?.toFullReturn?.types?.[0] ===
                                  'hotel' ? (
                                  <svg
                                    fill='#000000'
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    id='hotel-3'
                                    data-name='Line Color'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='icon line-color'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      <rect
                                        id='secondary'
                                        x={12}
                                        y={15}
                                        width={4}
                                        height={6}
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                      <path
                                        id='primary'
                                        d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                    </g>
                                  </svg>
                                ) : data?.toFullReturn.types?.[0] ===
                                  'station' ? (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <path
                                        d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                        stroke='#61473a'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />{' '}
                                    </g>
                                  </svg>
                                ) : (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='-3 0 20 20'
                                    version='1.1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    fill='#000000'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <title>
                                        pin_sharp_circle [#624]
                                      </title>{' '}
                                      <desc>Created with Sketch.</desc>{' '}
                                      <defs> </defs>{' '}
                                      <g
                                        id='Page-1'
                                        stroke='none'
                                        strokeWidth={1}
                                        fill='none'
                                        fillRule='evenodd'>
                                        {' '}
                                        <g
                                          id='Dribbble-Light-Preview'
                                          transform='translate(-223.000000, -5439.000000)'
                                          fill='#61473a'>
                                          {' '}
                                          <g
                                            id='icons'
                                            transform='translate(56.000000, 160.000000)'>
                                            {' '}
                                            <path
                                              d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                              id='pin_sharp_circle-[#624]'>
                                              {' '}
                                            </path>{' '}
                                          </g>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                )}
                                {data?.toFullReturn?.address?.split(',')[0]}{' '}
                                <svg
                                  width='64px'
                                  height='64px'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='arrow'>
                                  <g
                                    id='SVGRepo_bgCarrier'
                                    stroke-width='0'></g>
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'></g>
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <path
                                      d='M12 20L18 14M12 20L6 14M12 20L12 9.5M12 4V6.5'
                                      stroke='#64666b'
                                      stroke-width='1.5'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'></path>{' '}
                                  </g>
                                </svg>
                              </h3>
                              <p>{data?.toFullReturn?.address}</p>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className='car_class'>
                      <h1>Select Car</h1>
                      <p>All prices include VAT, fees, and tolls</p>
                      <div>
                        {data?.persons <= 3 && (
                          <div
                            className={
                              activeCarReturn === 'business' ? 'active' : ''
                            }
                            onClick={() => setActiveCarReturn('business')}>
                            <img src='/img/eclass.webp' alt='Business' />
                            <div>
                              <h3>Business Class</h3>
                              <div className='icons'>
                                <p>
                                  <img src='/img/users.svg' alt='Luggage' />3
                                </p>
                                <p>
                                  <img src='/img/luggage.svg' alt='Luggage' />2
                                </p>
                              </div>
                              <p>Mercedes-Benz E-Class</p>
                              <p className='small'>Most popular</p>
                            </div>
                            <p className='price'>
                              €
                              {formatAmount(
                                infoApiReturn?.props?.pageProps?.stepData
                                  ?.serviceClasses?.[0]?.price?.totalAmount,
                                infoApiReturn?.props?.pageProps?.stepData
                                  ?.serviceClasses?.[0]?.price?.decimalSeparator
                              )}
                            </p>
                          </div>
                        )}
                        <div
                          className={`${
                            activeCarReturn === 'van' ? 'active' : ''
                          } ${data?.persons > 3 ? 'solo' : ''}`}
                          onClick={() => setActiveCarReturn('van')}
                          style={{ borderTop: 'none' }}>
                          <img src='/img/vclass.webp' alt='Business' />
                          <div>
                            <h3>Business Van/SUV</h3>
                            <div className='icons'>
                              <p>
                                <img src='/img/users.svg' alt='Luggage' />5
                              </p>
                              <p>
                                <img src='/img/luggage.svg' alt='Luggage' />5
                              </p>
                            </div>
                            <p>Mercedes-Benz V-Class</p>
                            <p className='small'>Most spacious</p>
                          </div>
                          <p className='price'>
                            €
                            {formatAmount(
                              infoApiReturn?.props?.pageProps?.stepData
                                ?.serviceClasses?.[2]?.price?.totalAmount,
                              infoApiReturn?.props?.pageProps?.stepData
                                ?.serviceClasses?.[2]?.price?.decimalSeparator
                            )}
                          </p>
                        </div>
                        {data?.persons <= 3 &&
                          infoApiReturn?.props?.pageProps?.stepData
                            ?.serviceClasses?.[3]?.price?.totalAmount && (
                            <div
                              className={
                                activeCarReturn === 'sclass' ? 'active' : ''
                              }
                              onClick={() => setActiveCarReturn('sclass')}
                              style={{ borderTop: 'none' }}>
                              <img src='/img/sclass.webp' alt='Business' />
                              <div>
                                <h3>First Class</h3>
                                <div className='icons'>
                                  <p>
                                    <img src='/img/users.svg' alt='Luggage' />3
                                  </p>
                                  <p>
                                    <img src='/img/luggage.svg' alt='Luggage' />
                                    2
                                  </p>
                                </div>
                                <p>Mercedes-Benz S-Class</p>
                                <p className='small'>Most luxurious</p>
                              </div>
                              <p className='price'>
                                €
                                {formatAmount(
                                  infoApiReturn?.props?.pageProps?.stepData
                                    ?.serviceClasses?.[3]?.price?.totalAmount,
                                  infoApiReturn?.props?.pageProps?.stepData
                                    ?.serviceClasses?.[3]?.price
                                    ?.decimalSeparator
                                )}
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='add-ons'>
                      <h2>Add-ons</h2>
                      <p>
                        If you need additional services, just tick the boxes or
                        let us know in the field below
                      </p>
                      <FormControlLabel
                        control={<Checkbox />}
                        label='Baby Seat (€15)'
                        checked={isBabySeatReturn}
                        onChange={() => setIsBabySeatReturn(!isBabySeatReturn)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label='Extra Stop (€20)'
                        checked={isExtraStopReturn}
                        onChange={() =>
                          setIsExtraStopReturn(!isExtraStopReturn)
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label='Other Services (€20)'
                        checked={isOtherServicesReturn}
                        onChange={() =>
                          setIsOtherServicesReturn(!isOtherServicesReturn)
                        }
                      />
                      <h4>Other Services</h4>
                      <textarea
                        placeholder='Type here...'
                        value={otherServicesTextReturn}
                        onChange={(e) =>
                          setOtherServicesTextReturn(e.target.value)
                        }
                      />
                    </div>
                    <div className='info'>
                      <div>
                        <p>All classes include:</p>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>
                            Free cancellation up until 24 hours before pickup
                          </p>
                        </div>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>Free 60 minutes of wait time</p>
                        </div>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>Meet & Greet</p>
                        </div>
                        <div>
                          <img src='/img/check_circle.svg' alt='Circle' />
                          <p>Complimentary bottle of water</p>
                        </div>
                      </div>
                      <div>
                        <p>Please note:</p>
                        <div style={{ marginBottom: '24px' }}>
                          <img
                            src='/img/warning.svg'
                            alt='Circle'
                            style={{ marginTop: '2px' }}
                          />
                          <p>
                            Guest/luggage capacities must be abided by for
                            safety reasons. If you are unsure, select a larger
                            class as chauffeurs may turn down service when they
                            are exceeded.
                          </p>
                        </div>
                        <div>
                          <img
                            src='/img/warning.svg'
                            alt='Circle'
                            style={{ marginTop: '2px' }}
                          />
                          <p>
                            The vehicle images above are examples. You may get a
                            different vehicle of similar quality.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : step === 2 ? (
              <Step2
                data={data}
                personalInfo={personalInfo}
                setPersonalInfo={setPersonalInfo}
                isFlight={isFlight}
              />
            ) : step === 3 ? (
              <Step3
                data={data}
                personalInfo={personalInfo}
                setPersonalInfo={setPersonalInfo}
                isFlight={isFlight}
              />
            ) : (
              step === 4 && <Step4 setStep={setStep} />
            )}
          </S.Booking>
          {step !== 5 && (
            <S.Fixed>
              <div>
                <div>
                  <h3>
                    Total:{' '}
                    <span>
                      €
                      {data?.tab === 'with_return'
                        ? (
                            parseFloat(price) +
                            (isBabySeat ? 15 : 0) +
                            (isExtraStop ? 20 : 0) +
                            (isOtherServices ? 20 : 0) +
                            (parseFloat(priceReturn) +
                              (isBabySeatReturn ? 15 : 0) +
                              (isExtraStopReturn ? 20 : 0) +
                              (isOtherServicesReturn ? 20 : 0))
                          ).toFixed(2)
                        : (
                            parseFloat(price) +
                            (isBabySeat ? 15 : 0) +
                            (isExtraStop ? 20 : 0) +
                            (isOtherServices ? 20 : 0)
                          ).toFixed(2)}
                    </span>
                  </h3>
                </div>
                <div>
                  <Link to='/'>Back to Homepage</Link>
                  <button
                    disabled={step === 4}
                    className={step === 4 ? 'disabled' : ''}
                    onClick={() => {
                      if (step === 1) {
                        submit();
                      } else if (step === 2) {
                        submitStep2();
                      } else if (step === 3) {
                        submitStep3();
                      }
                    }}>
                    Continue
                  </button>
                </div>
              </div>
            </S.Fixed>
          )}
        </>
      )}
    </>
  );
};

export default Booking;
