import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <>
      <S.Footer>
        <div>
          <div>
            <img src='/img/logo_new.png' alt='Lux Limo' />
            <p>
              Lux Limo offers premium transportation services tailored to your
              needs, ensuring comfort, reliability, and professionalism every
              step of the way. From airport transfers to special events, trust
              Lux Limo for an unparalleled journey.
            </p>
          </div>
          <div>
            <h5>Company</h5>
            <HashLink to='/#book'>Book a Ride</HashLink>
            <Link to='/faq'>FAQ</Link>
            <Link to='/contact-us'>Contact us</Link>
            <Link to='/terms'>Terms</Link>
          </div>
          <div>
            <h5>Contact Details</h5>
            <div>
              <div>
                <h6>Phone</h6>
                <p>+393 273 368 720</p>
              </div>
            </div>
            <div>
              <div>
                <h6>Email Address</h6>
                <p>info@luxlimo.it</p>
              </div>
            </div>
            <div>
              <div>
                <h6>Location</h6>
                <p>Quarters across Italy</p>
              </div>
            </div>
            <div>
              <div>
                <h6>Company Name</h6>
                <p>Royal Travel S.A.S</p>
              </div>
            </div>
          </div>
        </div>
      </S.Footer>
      <div
        style={{
          background: '#382b25',
          padding: '10px',
          color: '#fff',
          fontSize: '14px',
          textAlign: 'center',
        }}>
        @ Lux Limo Lt All Rights Reserved 2023
      </div>
    </>
  );
};

export default Footer;
