import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import { HashLink } from 'react-router-hash-link';

const Destinations = () => {
  return (
    <S.Destinations>
      <div>
        <h2>Popular Destinations</h2>
        <div className='top'>
          <div>
            <div>
              <HashLink to='/#book'>Book a Ride</HashLink>
              <img src='/img/malpensa.webp' alt='Malpensa Transfer' />
              <div>
                <h3>Malpensa Airport (MXP)</h3>
                <p>
                  From <span>€119</span>
                </p>
                <svg
                  viewBox='0 -10.43 47.856 47.856'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#61473a'>
                  <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                  <g
                    id='SVGRepo_tracerCarrier'
                    stroke-linecap='round'
                    stroke-linejoin='round'></g>
                  <g id='SVGRepo_iconCarrier'>
                    {' '}
                    <g
                      id='_4'
                      data-name='4'
                      transform='translate(-533.889 -162.466)'>
                      {' '}
                      <path
                        id='Path_207'
                        data-name='Path 207'
                        d='M581.745,181.466s0-11.137-8.378-11.137c-5.709,0-30.838,3.292-30.838,3.292l-3.748-5.155h-4.892l5.5,13h11.558l-4.766,8h4.42l8.512-8Z'
                        fill='#61473a'></path>{' '}
                      <path
                        id='Path_208'
                        data-name='Path 208'
                        d='M563.587,169.2l-11.262-6.737H547.15l7.653,7.569Z'
                        fill='#61473a'></path>{' '}
                    </g>{' '}
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <HashLink to='/#book'>Book a Ride</HashLink>
              <img src='/img/linate.png' alt='Malpensa Transfer' />
              <div>
                <h3>Linate Airport (LIN)</h3>
                <p>
                  From <span>€69</span>
                </p>
                <svg
                  viewBox='0 -10.43 47.856 47.856'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#61473a'>
                  <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                  <g
                    id='SVGRepo_tracerCarrier'
                    stroke-linecap='round'
                    stroke-linejoin='round'></g>
                  <g id='SVGRepo_iconCarrier'>
                    {' '}
                    <g
                      id='_4'
                      data-name='4'
                      transform='translate(-533.889 -162.466)'>
                      {' '}
                      <path
                        id='Path_207'
                        data-name='Path 207'
                        d='M581.745,181.466s0-11.137-8.378-11.137c-5.709,0-30.838,3.292-30.838,3.292l-3.748-5.155h-4.892l5.5,13h11.558l-4.766,8h4.42l8.512-8Z'
                        fill='#61473a'></path>{' '}
                      <path
                        id='Path_208'
                        data-name='Path 208'
                        d='M563.587,169.2l-11.262-6.737H547.15l7.653,7.569Z'
                        fill='#61473a'></path>{' '}
                    </g>{' '}
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <HashLink to='/#book'>Book a Ride</HashLink>
              <img src='/img/bergamo.png' alt='Malpensa Transfer' />
              <div>
                <h3>Bergamo Airport (BGY)</h3>
                <p>
                  From <span>€119</span>
                </p>
                <svg
                  viewBox='0 -10.43 47.856 47.856'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#61473a'>
                  <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                  <g
                    id='SVGRepo_tracerCarrier'
                    stroke-linecap='round'
                    stroke-linejoin='round'></g>
                  <g id='SVGRepo_iconCarrier'>
                    {' '}
                    <g
                      id='_4'
                      data-name='4'
                      transform='translate(-533.889 -162.466)'>
                      {' '}
                      <path
                        id='Path_207'
                        data-name='Path 207'
                        d='M581.745,181.466s0-11.137-8.378-11.137c-5.709,0-30.838,3.292-30.838,3.292l-3.748-5.155h-4.892l5.5,13h11.558l-4.766,8h4.42l8.512-8Z'
                        fill='#61473a'></path>{' '}
                      <path
                        id='Path_208'
                        data-name='Path 208'
                        d='M563.587,169.2l-11.262-6.737H547.15l7.653,7.569Z'
                        fill='#61473a'></path>{' '}
                    </g>{' '}
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <HashLink to='/#book'>Book a Ride</HashLink>
              <img src='/img/como.png' alt='Malpensa Transfer' />
              <div>
                <h3>Lake Como</h3>
                <p>
                  From <span>€189</span>
                </p>
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#000000'>
                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                  <g
                    id='SVGRepo_tracerCarrier'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <g id='SVGRepo_iconCarrier'>
                    {' '}
                    <title />{' '}
                    <g data-name='Trees' id='Trees-2'>
                      {' '}
                      <path
                        d='M8.4062,9.3842a2.7484,2.7484,0,0,0-2.262-1.677v3.705l1.17-1.053Z'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='8.627 11.867 8.627 10.918 7.327 12.101 6.144 13.167 6.144 13.973 7.327 12.972 8.627 11.867'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='8.627 16.014 7.327 17.158 6.144 18.198 6.144 20.681 8.627 20.681 8.627 16.014'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='4.844 18.172 3.648 17.093 2.348 15.91 2.348 20.681 4.844 20.681 4.844 18.172'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <rect
                        height='6.175'
                        style={{ fill: '#61473a' }}
                        width='1.3'
                        x='4.8442'
                        y='20.6812'
                      />{' '}
                      <path
                        d='M4.8442,11.36V7.7072a2.779,2.779,0,0,0-2.236,1.586l1.053.975Z'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='4.844 13.934 4.844 13.128 3.648 12.023 2.348 10.814 2.348 11.75 3.648 12.894 4.844 13.934'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='4.844 16.417 4.844 15.65 3.648 14.61 2.348 13.479 2.348 14.155 3.648 15.338 4.844 16.417'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='8.627 14.285 8.627 13.57 7.327 14.675 6.144 15.689 6.144 16.469 7.327 15.429 8.627 14.285'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <path
                        d='M29.443,9.3842a2.7484,2.7484,0,0,0-2.262-1.677v3.705l1.17-1.053Z'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='28.364 12.101 27.181 13.167 27.181 13.973 28.364 12.972 29.664 11.867 29.664 10.918 28.364 12.101'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='27.181 18.198 27.181 20.681 29.664 20.681 29.664 16.014 28.364 17.158 27.181 18.198'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='25.878 26.856 27.178 26.856 27.178 20.681 25.881 20.681 25.881 18.172 24.685 17.093 23.385 15.91 23.385 20.681 25.878 20.681 25.878 26.856'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <path
                        d='M25.881,11.36V7.7072a2.779,2.779,0,0,0-2.236,1.586l1.053.975Z'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='25.881 13.934 25.881 13.128 24.685 12.023 23.385 10.814 23.385 11.75 24.685 12.894 25.881 13.934'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='25.881 16.417 25.881 15.65 24.685 14.61 23.385 13.479 23.385 14.155 24.685 15.338 25.881 16.417'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='27.181 15.689 27.181 16.469 28.364 15.429 29.664 14.285 29.664 13.57 28.364 14.675 27.181 15.689'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <path
                        d='M19.7121,5.9583a3.4786,3.4786,0,0,0-2.8628-2.1225V8.525L18.33,7.1923Z'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='19.992 9.101 19.992 7.9 18.346 9.397 16.849 10.746 16.849 11.766 18.346 10.499 19.992 9.101'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='19.992 14.349 18.346 15.797 16.849 17.114 16.849 20.256 19.992 20.256 19.992 14.349'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='15.204 17.081 13.69 15.715 12.045 14.218 12.045 20.256 15.204 20.256 15.204 17.081'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <rect
                        height='6.604'
                        style={{ fill: '#61473a' }}
                        width='1.638'
                        x='15.2052'
                        y='20.2522'
                      />{' '}
                      <path
                        d='M15.204,8.4591V3.8358a3.517,3.517,0,0,0-2.83,2.0073l1.3327,1.234Z'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='15.204 11.717 15.204 10.697 13.69 9.298 12.045 7.768 12.045 8.953 13.69 10.401 15.204 11.717'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='15.204 14.86 15.204 13.889 13.69 12.572 12.045 11.141 12.045 11.997 13.69 13.494 15.204 14.86'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <polygon
                        points='19.992 12.161 19.992 11.256 18.346 12.655 16.849 13.938 16.849 14.925 18.346 13.609 19.992 12.161'
                        style={{ fill: '#61473a' }}
                      />{' '}
                      <rect
                        height='1.3'
                        style={{ fill: '#61473a' }}
                        width='26.0178'
                        x='2.9982'
                        y='28.1562'
                      />{' '}
                    </g>{' '}
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <HashLink to='/#book'>Book a Ride</HashLink>
            <img src='/img/duomo.png' alt='Duomo Transfers' />
            <div>
              <h3>Duomo, Milan</h3>
              <p>
                From <span>€98</span>
              </p>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                fill='#000000'>
                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                <g
                  id='SVGRepo_tracerCarrier'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <title />{' '}
                  <g data-name='Trees' id='Trees-2'>
                    {' '}
                    <path
                      d='M8.4062,9.3842a2.7484,2.7484,0,0,0-2.262-1.677v3.705l1.17-1.053Z'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='8.627 11.867 8.627 10.918 7.327 12.101 6.144 13.167 6.144 13.973 7.327 12.972 8.627 11.867'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='8.627 16.014 7.327 17.158 6.144 18.198 6.144 20.681 8.627 20.681 8.627 16.014'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='4.844 18.172 3.648 17.093 2.348 15.91 2.348 20.681 4.844 20.681 4.844 18.172'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <rect
                      height='6.175'
                      style={{ fill: '#61473a' }}
                      width='1.3'
                      x='4.8442'
                      y='20.6812'
                    />{' '}
                    <path
                      d='M4.8442,11.36V7.7072a2.779,2.779,0,0,0-2.236,1.586l1.053.975Z'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='4.844 13.934 4.844 13.128 3.648 12.023 2.348 10.814 2.348 11.75 3.648 12.894 4.844 13.934'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='4.844 16.417 4.844 15.65 3.648 14.61 2.348 13.479 2.348 14.155 3.648 15.338 4.844 16.417'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='8.627 14.285 8.627 13.57 7.327 14.675 6.144 15.689 6.144 16.469 7.327 15.429 8.627 14.285'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <path
                      d='M29.443,9.3842a2.7484,2.7484,0,0,0-2.262-1.677v3.705l1.17-1.053Z'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='28.364 12.101 27.181 13.167 27.181 13.973 28.364 12.972 29.664 11.867 29.664 10.918 28.364 12.101'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='27.181 18.198 27.181 20.681 29.664 20.681 29.664 16.014 28.364 17.158 27.181 18.198'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='25.878 26.856 27.178 26.856 27.178 20.681 25.881 20.681 25.881 18.172 24.685 17.093 23.385 15.91 23.385 20.681 25.878 20.681 25.878 26.856'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <path
                      d='M25.881,11.36V7.7072a2.779,2.779,0,0,0-2.236,1.586l1.053.975Z'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='25.881 13.934 25.881 13.128 24.685 12.023 23.385 10.814 23.385 11.75 24.685 12.894 25.881 13.934'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='25.881 16.417 25.881 15.65 24.685 14.61 23.385 13.479 23.385 14.155 24.685 15.338 25.881 16.417'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='27.181 15.689 27.181 16.469 28.364 15.429 29.664 14.285 29.664 13.57 28.364 14.675 27.181 15.689'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <path
                      d='M19.7121,5.9583a3.4786,3.4786,0,0,0-2.8628-2.1225V8.525L18.33,7.1923Z'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='19.992 9.101 19.992 7.9 18.346 9.397 16.849 10.746 16.849 11.766 18.346 10.499 19.992 9.101'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='19.992 14.349 18.346 15.797 16.849 17.114 16.849 20.256 19.992 20.256 19.992 14.349'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='15.204 17.081 13.69 15.715 12.045 14.218 12.045 20.256 15.204 20.256 15.204 17.081'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <rect
                      height='6.604'
                      style={{ fill: '#61473a' }}
                      width='1.638'
                      x='15.2052'
                      y='20.2522'
                    />{' '}
                    <path
                      d='M15.204,8.4591V3.8358a3.517,3.517,0,0,0-2.83,2.0073l1.3327,1.234Z'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='15.204 11.717 15.204 10.697 13.69 9.298 12.045 7.768 12.045 8.953 13.69 10.401 15.204 11.717'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='15.204 14.86 15.204 13.889 13.69 12.572 12.045 11.141 12.045 11.997 13.69 13.494 15.204 14.86'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <polygon
                      points='19.992 12.161 19.992 11.256 18.346 12.655 16.849 13.938 16.849 14.925 18.346 13.609 19.992 12.161'
                      style={{ fill: '#61473a' }}
                    />{' '}
                    <rect
                      height='1.3'
                      style={{ fill: '#61473a' }}
                      width='26.0178'
                      x='2.9982'
                      y='28.1562'
                    />{' '}
                  </g>{' '}
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className='bottom'>
          <div>
            <h3>Have a trip in mind?</h3>
            <p>Enter your pickup and drop-off locations to see the price.</p>
          </div>
          <HashLink to='/#book'>Book a Business Ride</HashLink>
        </div>
      </div>
    </S.Destinations>
  );
};

export default Destinations;
