import styled from 'styled-components';

export const Whyus = styled.div`
  padding: 72px 0;
  background: url('/img/overlay_bg.png');
  background-size: cover;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @media (max-width: 1220px) {
      padding: 0 15px;
    }
    & > p {
      text-align: center;
      margin-bottom: 64px;
      color: #fff;
    }
    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 32px;
      & > div {
        width: calc(33% - 18px);
        display: flex;
        align-items: center;
        gap: 12px;
        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
        svg,
        img {
          max-width: 40px;
          margin-top: 2px;
          height: auto;
        }
        .seat {
          max-width: 35px;
          margin-right: 5px;
        }
        h5 {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 4px;
          color: #fff;
        }
        p {
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
  h2 {
    font-size: 42px;
    text-align: center;
    margin-bottom: 8px;
    color: #fff;
    @media (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 12px;
    }
  }
`;
