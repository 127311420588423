import * as S from './styles';

const Terms = () => {
  return (
    <S.Terms>
      <section className='container_container__bgxE0 staticPageContent_staticPageContentContainer__qnuQP'>
        <h1
          className='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'
          node='[object Object]'>
          Lux Limo Terms and Conditions
        </h1>
        <ol className='' node='[object Object]'>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#1general'
              node='[object Object]'>
              General
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#2contractualrelationshipandconclusionofcontract'
              node='[object Object]'>
              Contractual Relationship and Conclusion of Contract
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#3userregistrationrequirementsfortheuseofbltools'
              node='[object Object]'>
              User Registration Requirements for the Use of Lux Limo
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#4selectedtopicsofthecontractofcarriageforthebenefitoftheuser'
              node='[object Object]'>
              Selected Topics of the Contract of Carriage for the Benefit of the
              User
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#5compensationandpayment'
              node='[object Object]'>
              Compensation and Payment
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#6liability'
              node='[object Object]'>
              Liability
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#7changestotheofferbyLux Limo'
              node='[object Object]'>
              Changes to the Offer by Lux Limo
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#8protectionofcontentgrantingofrightsofusetobltools'
              node='[object Object]'>
              Protection of Content, Granting of Rights of Use to Lux Limo Tools
            </a>
          </li>
          <li className='StrToHtml_li__9lISm' node='[object Object]'>
            <a
              className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
              href='#9finalprovisions'
              node='[object Object]'>
              Final Provisions
            </a>
          </li>
        </ol>
        <h2
          className='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'
          node='[object Object]'>
          <a
            className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
            href=''
            id='1general'
            node='[object Object]'
          />
          1. General
        </h2>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Lux Limo enables its Users to book travel services over its online
          platform, through the association with other online platforms. At Lux
          Limo, we take pride in providing a comprehensive service that
          encompasses every aspect of your journey. From the moment you book
          with us to the completion of your trip, we handle every detail with
          care and precision. Unlike traditional models, where the service is
          merely arranging transportation through a third-party provider, we
          take full responsibility for every step of your experience. This means
          ensuring seamless booking processes, reliable transportation, and
          attentive customer service throughout your journey.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          These Terms and Conditions (hereinafter referred to as “Terms”) are
          part of every agreement between Users with the arrangement of a travel
          service by Lux Limo. They also describe in detail the travel services
          which are offered through the direct entitlement created by Lux Limo
          between the User and the TSP.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Deviations from the User Terms, even in the case of letters of
          confirmation and unreserved services, are hereby rejected. Only if the
          management of Lux Limo has expressly agreed in writing, does this not
          apply.
        </p>
        <h2
          className='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'
          node='[object Object]'>
          <a
            className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
            href=''
            id='2contractualrelationshipandconclusionofcontract'
            node='[object Object]'
          />
          2. Contractual Relationship and Conclusion of Contract
        </h2>
        <h3
          className='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          2.1 Contractual Relationship
        </h3>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          At Lux Limo, we handle all aspects of your transportation needs
          directly. When you book with us, you're not just arranging a service
          through a third party – we take full responsibility for providing you
          with a seamless travel experience from start to finish.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          When you use Lux Limo, you're not simply a pass-through customer.
          We're not just an intermediary; we're the service provider. Our fees
          encompass everything from booking to the actual transportation,
          ensuring that your journey with us is hassle-free and enjoyable.
        </p>
        <h3
          className='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          2.2 Conclusion
        </h3>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          By submitting a completed booking form on our website or contacting us
          by phone, you're making a direct request for transportation services
          from Lux Limo. Our goal is to meet your travel needs efficiently and
          effectively.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          After you provide your ride details, we'll send you a confirmation
          email to let you know that we've received your request. This
          confirmation indicates that your request has been acknowledged by Lux
          Limo.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Your booking with Lux Limo is confirmed when you receive a separate
          "Booking Confirmation" email from us. At this point, you can expect us
          to fulfill your transportation needs directly, without the involvement
          of any third-party service providers.
        </p>

        <h2
          className='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'
          node='[object Object]'>
          <a
            className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
            href=''
            id='4selectedtopicsofthecontractofcarriageforthebenefitoftheuser'
            node='[object Object]'>
            3. Selected Topics of Lux Limo's Carriage Contract
          </a>
        </h2>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          When you make travel arrangements with Lux Limo, your entitlement to
          carriage with our transportation service providers (TSPs) is arranged
          directly by us. This means that any demands or requests regarding your
          ride should be agreed upon with Lux Limo.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Here are the conditions that apply to your carriage entitlement,
          arranged by Lux Limo, over the TSP:
        </p>
        <h3
          className='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          4.1 Transfer Rides / Hourly Bookings, Service Changes
        </h3>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          You can choose between transfer rides and hourly bookings for your
          ride request. If additional services are required compared to the
          original request, Lux Limo will endeavor to accommodate them.
          Additional services may result in extra costs.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Changes to your travel arrangements can be made after the contract is
          concluded, subject to availability and in accordance with the payment
          terms.
        </p>
        <h4>4.1.1</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          For transfer services, the quoted price is valid for direct routes
          between start and destination addresses. Additional fees may apply for
          stopovers.
        </p>
        <h4>4.1.2</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Hourly bookings must conclude within the municipal area of the pickup
          location and begin at the confirmed pick-up time.
        </p>
        <h3
          className='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          4.2 Vehicle Class / Model, Upgrades
        </h3>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Lux Limo offers various vehicle classes for your ride request, such as
          Business Class, Business Van/SUV, or First Class. Vehicle images
          provided are for illustrative purposes only and do not guarantee a
          specific model.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Subject to availability, Lux Limo may offer upgrades to higher vehicle
          classes at additional cost.
        </p>
        <h3
          className='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          4.3 Transportation Safety, Consequences
        </h3>

        <h4>4.3.1 Luggage, Animals</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          The price stated in the Booking Confirmation includes the specified
          number of luggage pieces indicated on the booking form. Any excess
          luggage, oversized items, or transportation of animals not mentioned
          during booking may result in additional charges, as outlined in the
          Booking Confirmation.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          The transportation service provider (TSP) reserves the right to
          decline carriage of luggage and/or animals that were not previously
          agreed upon. This also applies to animals not contained in suitable
          transport containers.
        </p>
        <h4>4.3.2 Carriage of Children</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Parents or guardians should request safety seats for children as an
          additional comment during booking, specifying the number and age of
          children requiring transportation and the type of seating needed.
        </p>
        <h4>4.3.3 Information on Number of Guests and Pieces of Luggage</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Maximum guest and luggage numbers specified for each vehicle are
          estimates based on factors such as size and weight. These are not
          binding. The TSP may refuse carriage of guests or luggage if they
          believe it compromises space or safety conditions.
        </p>
        <h4>4.3.4 Prevention of Carriage</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          The TSP reserves the right to refuse carriage if requirements under
          Section 4.3 were not communicated properly by the User. In such cases,
          this does not affect the payment made by the User to Lux Limo for the
          booked transportation.
        </p>
        <h3
          className='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          4.4 Delays
        </h3>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Lux Limo understands that exceptional circumstances such as air
          traffic controller strikes or extreme weather conditions may occur.
          While we strive to minimize delays, in such cases, Users may need to
          accept longer waiting periods or last-minute cancellations.
        </p>

        <h4>4.3.1 Luggage, Animals</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          The price stated in the Booking Confirmation includes the specified
          number of luggage pieces indicated on the booking form. Any excess
          luggage, oversized items, or transportation of animals not mentioned
          during booking may result in additional charges, as outlined in the
          Booking Confirmation.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          The transportation service provider (TSP) reserves the right to
          decline carriage of luggage and/or animals that were not previously
          agreed upon. This also applies to animals not contained in suitable
          transport containers.
        </p>
        <h4>4.3.2 Carriage of Children</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Parents or guardians should request safety seats for children as an
          additional comment during booking, specifying the number and age of
          children requiring transportation and the type of seating needed.
        </p>
        <h4>4.3.3 Information on Number of Guests and Pieces of Luggage</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Maximum guest and luggage numbers specified for each vehicle are
          estimates based on factors such as size and weight. These are not
          binding. The TSP may refuse carriage of guests or luggage if they
          believe it compromises space or safety conditions.
        </p>
        <h4>4.3.4 Prevention of Carriage</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          The TSP reserves the right to refuse carriage if requirements under
          Section 4.3 were not communicated properly by the User. In such cases,
          this does not affect the payment made by the User to Lux Limo for the
          booked transportation.
        </p>
        <h3
          className='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          4.5 Cancellations, Changes to Booking, and No-Shows
        </h3>
        <h4>4.5.1 Cancellation</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          (a) Transfer Services
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          For transfer services, cancellation is free of charge if made more
          than 24 hours before the agreed pickup time. Cancellations within 24
          hours of the pickup time will incur the full price of the ride.
          Cancellations must be made through our App or Website.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          (b) Hourly Bookings
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          For hourly bookings, cancellation is free of charge if made more than
          24 hours before the agreed pickup time. Cancellations within 24 hours
          of the pickup time will incur the full price of the ride.
          Cancellations must be made through our App or Website.
        </p>
        <h4>4.5.2 Changes to Booking</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Changes to bookings are considered new bookings and are subject to the
          cancellation policy outlined in Section 4.5.1 above. Compensation
          claims for the original ride may apply.
        </p>
        <h4>4.5.3 No-Shows without cancellation, Delay to the User</h4>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          In case of a no-show without prior cancellation, the User forfeits
          their entitlement to carriage. However, this does not affect Lux
          Limo's compensation claim against the User.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          (a) Transfer Services
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          A ride is considered a no-show if the User or guest does not show up
          without prior cancellation within 30 minutes after the agreed pickup
          time. The full price of the ride must be paid in such cases, and
          waiting time surcharges do not apply.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          For airport or train station pickups, a ride is considered a no-show
          if the User or passenger does not show up without prior cancellation
          within 60 minutes after the agreed pickup time. The full price of the
          ride must be paid.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Situations where the pickup time was agreed upon later by phone are
          excluded from this rule. Any waiting time surcharges must be paid as
          described in Section 5.3.1. Generally, passengers are not entitled to
          change the pickup time.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          (b) Hourly Bookings
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          A ride is considered a no-show if the User or passenger does not show
          up without prior cancellation after the expiration of the booked hours
          following the agreed pickup time. The full price of the ride must be
          paid.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          For airport or train station pickups, a ride is considered a no-show
          if the User or passenger does not show up without prior cancellation
          after the expiration of the booked hours following the agreed pickup
          time. The full price of the ride must be paid.
        </p>
        <p
          className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Situations where the pickup time was agreed upon later by phone are
          excluded from this rule. Hourly bookings always start at the confirmed
          pickup time as described in Section 4.1.2. Any extensions to the
          hourly booking must be compensated as described in Section 5.2.
          Generally, passengers are not entitled to change the pickup time.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          4.6. Passenger Conduct
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo expects passengers to adhere to certain standards while
          enjoying our transportation services:
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Passengers are required to follow all road traffic regulations,
          including wearing seatbelts, and must comply with instructions from
          our professional chauffeurs. To ensure a safe and comfortable ride,
          passengers are prohibited from opening doors while the vehicle is in
          motion, throwing any objects from the vehicle, sticking body parts out
          of or shouting from the vehicle. If passengers wish to use any devices
          or facilities in the vehicle, they must seek brief instructions from
          our chauffeurs.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Smoking is strictly prohibited in the passenger area of the vehicle.
          Any violation of this rule will result in the guest being liable for
          the cost of cleaning the vehicle and compensating for the loss of
          business due to vehicle downtime.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          While we discourage the consumption of food in our vehicles, alcoholic
          drinks may be consumed only with prior consent.
        </p>

        <h2
          class='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'
          id='5compensationandpayment'>
          5. Compensation and Payment
        </h2>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          5.1 Pricing Policy
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo's pricing structure is outlined in the Booking Confirmation,
          taking into account factors such as the selected vehicle class,
          distance, advance booking, pickup time, and any additional services
          requested.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Special requests such as a multilingual chauffeur, individual vehicle
          labeling, additional stops, bulky luggage, or car seats for children
          may result in additional charges.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          5.2 Changes to Reservations
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo allows passengers to modify their travel arrangements even
          after the agreement has been finalized and the ride has commenced,
          provided it is feasible for Lux Limo.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Any spontaneous extension of the ride (distance or duration) at the
          request of the passenger will result in a recalculation of the service
          cost based on the current pricing structure. For hourly bookings, each
          additional 30-minute block is billable to ensure accurate invoicing.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Consequently, the compensation claim will be adjusted to reflect Lux
          Limo's increased expenses for extending the carriage service.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          If the actual distance or duration of the ride is less than originally
          booked, the price remains unchanged.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          5.3 Additional Charges
        </h3>

        <h4>5.3.1 Waiting Times for Transfer Services</h4>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          For transfer services, no additional charges apply at airports or
          train stations (excluding long-distance stations) for waiting times up
          to 60 minutes after the agreed pickup time. For all other locations, a
          15-minute grace period is allowed. Each additional minute of waiting
          time will incur a flat-rate charge based on the hourly booking prices
          and vehicle category.
        </p>

        <h4>5.3.2 Additional Kilometers for Hourly Bookings</h4>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Hourly bookings include a predefined number of kilometers per hour.
          Any additional kilometers will be subject to an additional charge,
          calculated based on the per-kilometer rate for the specific area and
          vehicle category.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          5.4 Payment Terms and Transaction Fees
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Passengers may settle their ride payments using credit cards. Lux Limo
          covers any incidental credit card charges. Transaction fees associated
          with bank transfers (e.g., due to currency conversion or different
          local accounts) are the responsibility of the passenger.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          5.5 Payment Reminders and Unpaid Invoices
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo reserves the right to levy an appropriate penalty fee for
          each payment reminder.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          For unpaid credit card invoices, Lux Limo charges passengers for the
          incurred expenses (bank, credit card company) and may apply an
          additional handling fee per incident.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          5.6 Invoice Dispatch and Payment Deadlines
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo sends invoices to users electronically for download from
          their Lux Limo account. Payment is due immediately for credit card
          payments, while bank transfer payments must adhere to the specified
          payment date on the invoice.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          5.7 Voucher Policy
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Vouchers can only be redeemed individually and cannot be combined with
          other vouchers. They are not exchangeable for cash.
        </p>

        <h2 class='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'>
          <a
            className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
            href=''
            id='6liability'
            node='[object Object]'
          />
          6. Liability
        </h2>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          6.1 Liability Principles
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo is liable for damages resulting from intentional or grossly
          negligent actions by Lux Limo, its agents, or subcontractors. However,
          TSPs and chauffeurs engaged for the travel service are not considered
          Lux Limo's agents or subcontractors; Lux Limo arranges direct carriage
          entitlements for users.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          For damages caused by ordinary negligence, Lux Limo is only liable for
          breaching fundamental contractual obligations and for foreseeable and
          typical damages. Fundamental contractual obligations are those
          necessary for proper contract execution and user reliance.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Liability limitations do not apply to express quality guarantees,
          intentional harm to life, body, or health, or claims under the Product
          Liability Act.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          6.2 Lux Limo Tools Content
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo is not liable for the accuracy, reliability, completeness, or
          timeliness of content and programs distributed as Lux Limo Tools,
          except in cases of intentional or gross negligence. This includes
          damages arising from errors, delays, technical issues, incorrect
          content, or interruptions in service.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          6.3 Third-Party Websites
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo assumes no responsibility for the content, accuracy,
          legality, or functionality of third-party websites linked through the
          Lux Limo Tools. User access to these sites via hyperlinks is at their
          own risk.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          6.4 Information Accuracy and Access Disruptions
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo is not responsible for the accuracy, completeness, or timely
          delivery of information to users or chauffeurs, except for content in
          the Booking Confirmation. Lux Limo also disclaims liability for access
          disruptions to Lux Limo Tools caused by force majeure or events beyond
          its control, including communication network failures.
        </p>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo does not guarantee uninterrupted or error-free website
          operation, nor does it guarantee error corrections.
        </p>

        <h3 class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'>
          6.5 User Release of Liability
        </h3>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Users release Lux Limo from all claims and expenses, including legal
          fees, arising from user violations of the contract or these terms when
          using Lux Limo Tools.
        </p>

        <h2 class='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'>
          <a
            className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
            href=''
            id='7changestotheofferbyLux Limo'
            node='[object Object]'
          />
          7. Changes to the Offer by Lux Limo
        </h2>
        <p class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'>
          Lux Limo reserves the right to make appropriate changes to Lux Limo
          Tools at any time to further enhance and improve their quality. Lux
          Limo may also temporarily or permanently suspend Lux Limo Tools
          without individual user notification for valid reasons.
        </p>

        <h2 class='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'>
          <a
            className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
            href=''
            id='8protectionofcontentgrantingofrightsofusetobltools'
            node='[object Object]'
          />
          8. Protection of Content, Granting of Rights of Use to Lux Limo Tools
        </h2>
        <p
          class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          The content within Lux Limo Tools is protected by copyright.
        </p>
        <p
          class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Lux Limo grants users a conditional and revocable right to use BL
          Tools in accordance with these terms. Any unauthorized use, including
          modification, copying, redistribution, or other improper purposes, is
          prohibited.
        </p>

        <h2 class='_H2Responsive_1eacj_109 _defaultTypographyColor_1eacj_356 StrToHtml_h2__sJZUQ'>
          <a
            className='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_link__Ei5bX'
            href=''
            id='9finalprovisions'
            node='[object Object]'
          />
          9. Final Provisions
        </h2>

        <h3
          class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          9.1 Entire Agreement, Written Form
        </h3>
        <p
          class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          These terms constitute the entire agreement between Lux Limo and users
          for the service. No subsidiary agreements exist. Changes and additions
          to this agreement must be in written form; digital form is not
          sufficient. The same applies to changes or additions to this
          requirement of written form.
        </p>

        <h3
          class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          9.2 Amendment of Terms
        </h3>
        <p
          class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Lux Limo reserves the right to modify these terms. Notification of
          changes is made through unilateral declaration by publishing the new
          terms on Lux Limo's website and informing users. If users do not
          object to the new terms within 14 days of publication, the new terms
          apply. Continued use of Lux Limo's services implies acceptance of the
          new terms.
        </p>

        <h3
          class='_H3Responsive_1eacj_138 _defaultTypographyColor_1eacj_356 StrToHtml_h3__6cAPI'
          node='[object Object]'>
          9.3 Setoff, Retention, and Assignment
        </h3>
        <p
          class='_BodyResponsive_1eacj_225 _defaultTypographyColor_1eacj_356 StrToHtml_p__dpJBX'
          node='[object Object]'>
          Users may only offset undisputed or legally determined counterclaims
          against Lux Limo. This also applies to user notices of defects. Users
          may only exercise a right of retention if their counterclaim arises
          from the same contractual relationship. The assignment of user claims
          against third parties from the contractual relationship without Lux
          Limo's written consent is prohibited.
        </p>
      </section>
    </S.Terms>
  );
};

export default Terms;
