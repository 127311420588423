import { useEffect, useState } from 'react';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../slices/paramsSlice';
import CheckoutForm from './Form';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Radio from '@mui/material/Radio';
import { PayPalButtons } from '@paypal/react-paypal-js';

const Step4 = ({ setStep }) => {
  const [selectedPercent, setSelectedPercent] = useState(30);
  const data = useSelector((state) => state.params.data);
  const integer = parseFloat(data?.price);
  const now = (integer * selectedPercent) / 100;
  const after = integer - now;
  const dispatch = useDispatch();
  const [showPayment, setShowPayment] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const submitFunc = () => {
    dispatch(
      setData({ ...data, deposit_percent: selectedPercent, left_to_pay: after })
    );
  };

  const history = useHistory();

  const createPaymentIntent = () => {
    setInProgress(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/create-payment-intent`, null, {
        params: {
          amount: now.toFixed(2) * 100,
        },
      })
      .then(async (r) => {
        const { clientSecret } = await r.data;
        setClientSecret(clientSecret);
        setInProgress(false);
      });
  };

  useEffect(() => {
    if (showPayment) {
      axios.get(`${process.env.REACT_APP_API_URL}/config`).then(async (r) => {
        const { publishableKey } = await r.data;
        setStripePromise(loadStripe(publishableKey));
      });
      createPaymentIntent();
    }
  }, [showPayment]);

  return (
    <S.Step4>
      <h1>Payment</h1>
      <p>
        To proceed with your transfer, a deposit is required to secure the
        booking. You have the option to select various percentage rates or
        settle the total amount upfront. Once done, we will handle the rest for
        you.
      </p>
      <h4>Select Deposit Percentage Upfront</h4>
      <div className={showPayment ? 'opacity' : ''}>
        <div className='selection'>
          <p
            className={selectedPercent === 30 ? 'active' : ''}
            onClick={() => !showPayment && setSelectedPercent(30)}>
            30%
          </p>
          <p
            className={selectedPercent === 100 ? 'active' : ''}
            onClick={() => !showPayment && setSelectedPercent(100)}>
            100%
          </p>
        </div>

        <div className='options'>
          <div>
            <Radio
              checked={paymentMethod === 'card'}
              onChange={() => setPaymentMethod('card')}
              value='a'
              sx={{
                '&, &.Mui-checked': {
                  color: '#9C7561',
                },
              }}
            />
            <img
              src='/img/payments.png'
              alt='paypal'
              onClick={() => setPaymentMethod('card')}
            />
          </div>
          <div>
            <Radio
              sx={{
                '&, &.Mui-checked': {
                  color: '#9C7561',
                },
              }}
              checked={paymentMethod === 'paypal'}
              onChange={() => setPaymentMethod('paypal')}
              value='a'
            />
            <img
              src='/img/paypal.png'
              alt='paypal'
              onClick={() => setPaymentMethod('paypal')}
            />
          </div>
        </div>
        <div className='proceed'>
          <div>
            <p>
              Paying Now: <span>€{now.toFixed(2)}</span>
            </p>
            <p>
              On Arrival (Cash or Card to the Driver):{' '}
              <span>€{after.toFixed(2)}</span>
            </p>
            <p>
              Total: <span>€{integer.toFixed(2)}</span>
            </p>
          </div>
          <button
            onClick={() => {
              setShowPayment(true);
              window.scrollBy({
                top: 400, // Scroll down by 300 pixels
                left: 0, // Do not scroll horizontally
                behavior: 'smooth', // Enable smooth scrolling
              });
            }}>
            Proceed to Payment
          </button>
        </div>
      </div>
      {showPayment &&
      paymentMethod === 'card' &&
      stripePromise &&
      clientSecret &&
      !inProgress ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            submit={submitFunc}
            stripePromis={stripePromise}
            goBack={() => setShowPayment(false)}
            setStep={setStep}
          />
        </Elements>
      ) : (
        showPayment &&
        paymentMethod === 'paypal' && (
          <div className='paypal_btn'>
            <p onClick={() => setShowPayment(false)}>
              <svg
                width='64px'
                height='30px'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  stroke-linecap='round'
                  stroke-linejoin='round'></g>
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <path
                    d='M12 20L12 4M12 4L18 10M12 4L6 10'
                    stroke='#9c7561'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'></path>{' '}
                </g>
              </svg>
              Go Back
            </p>
            <PayPalButtons
              style={{
                layout: 'horizontal',
                color: 'black',
                height: 52,
              }}
              forceReRender={[showPayment]}
              className='paypal_button'
              createOrder={(data, actions) => {
                return actions.order
                  .create({
                    purchase_units: [
                      {
                        amount: {
                          value: now.toFixed(2),
                        },
                      },
                    ],
                  })
                  .then((orderId) => {
                    console.log(orderId);
                    return orderId;
                  });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then(function () {
                  submitFunc();
                  setStep(5);
                  history.push('/booking?step=5');
                  console.log('here');
                });
              }}
            />
          </div>
        )
      )}
    </S.Step4>
  );
};

export default Step4;
