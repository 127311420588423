import { PaymentElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import * as S from './styles';
import { setAlert } from '../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import { setData } from '../../slices/paramsSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CheckoutForm({ submit, goBack, setStep }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    submit();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });
    console.log(error);
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error.message);
      setIsProcessing(false);

      return;
    } else if (error) {
      dispatch(setAlert({ message: 'Ups! Something went wrong!' }));
      setIsProcessing(false);

      return;
    } else {
      setStep(5);
      history.push('/booking?step=5');
    }

    setIsProcessing(false);
  };

  useEffect(() => {
    window.scrollBy({
      top: 400, // Scroll down by 300 pixels
      left: 0, // Do not scroll horizontally
      behavior: 'smooth', // Enable smooth scrolling
    });
  }, []);

  return (
    <S.FormPayment id='payment-form' onSubmit={handleSubmit}>
      <p onClick={() => goBack()}>
        <svg
          width='64px'
          height='30px'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
          <g
            id='SVGRepo_tracerCarrier'
            stroke-linecap='round'
            stroke-linejoin='round'></g>
          <g id='SVGRepo_iconCarrier'>
            {' '}
            <path
              d='M12 20L12 4M12 4L18 10M12 4L6 10'
              stroke='#9c7561'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'></path>{' '}
          </g>
        </svg>
        Go Back
      </p>
      <PaymentElement id='payment-element' />
      <button disabled={isProcessing || !stripe || !elements} id='submit'>
        <span id='button-text'>
          {isProcessing ? 'Processing ... ' : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
    </S.FormPayment>
  );
}
