import styled from 'styled-components';

export const Booking = styled.div`
  margin-top: 80px;
  max-width: 750px;
  margin: 120px auto;
  @media (max-width: 768px) {
    padding: 0 15px;
    margin-top: 84px;
    margin-bottom: 84px;
  }
  .steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 750px;
    margin: 0 auto;
    position: relative;
    &::after {
      position: absolute;
      width: 100%;
      border: 1px dashed #9c7561;
      content: '';
      top: 51%;
      transform: translate(0, -50%);
      z-index: 0;
      left: 0;
      @media (max-width: 768px) {
        width: 99%;
      }
    }
    p {
      position: relative;
      padding: 6px 12px;
      background-color: #fff;
      z-index: 3;
      color: #3e3935;
      &.active {
        color: #3e3935;
        background: #fff;
        border-radius: 30px;
        border: 1px solid #9c7561;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        z-index: 3;
      }
      &.completed {
        background: #9c7561;
        color: #fff;
        border-radius: 30px;
      }
    }
  }
  .main_info {
    max-width: 750px;
    margin: 32px auto;
    .hour_little {
      margin-top: -20px;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #63666b;
        display: inline-block;
        margin-left: 8px;
        margin-right: 6px;
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 400 !important;
      font-family: Inter, sans-serif;
      margin-bottom: 4px;
      position: relative;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: -45px;
        max-width: 25px;
      }
      svg {
        max-width: 20px;
        margin-right: 10px;
      }
    }
    p {
      font-size: 14px;
      margin-bottom: 32px;
      color: #64666b;
    }
  }
  .car_class {
    border-top: 1px solid #e3e5ea;
    padding-top: 16px;
    & > h1 {
      font-size: 32px;
      margin-bottom: 6px;
    }
    & > p {
      font-size: 14px;
      font-weight: 300;
      color: #64666b;
      margin-bottom: 24px;
    }
    & > div {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 8px;
    }
    & > div > div {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      padding: 24px;
      border: 1px solid #e3e5ea;
      gap: 32px;
      background: #fff;
      transition: transform 0.3s ease-in-out;
      @media (max-width: 768px) {
        flex-wrap: wrap;
        gap: 16px;
        justify-content: space-between;
      }
      &:first-of-type {
        border-radius: 8px 8px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }
      &.active {
        background: #eff2f6;
        outline: 2px solid #9c7561 !important;
        transform: scale(1.02);
        z-index: 2;
        position: relative;
      }
      img {
        max-width: 25%;
        height: auto;
        object-fit: contain;
        object-position: center;
        @media (max-width: 768px) {
          max-width: 60%;
          margin: 0 auto 16px;
        }
      }
      .price {
        margin-left: auto;
        font-size: 24px;
        font-weight: 800;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #9c7561;
        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
      .icons {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 5px;
        p {
          display: flex;
          align-items: center;
          color: #212121;
          font-weight: 600;
          svg,
          img {
            max-width: 20px;
            width: 100%;
            margin-right: 6px;
            margin-bottom: 0;
          }
        }
      }
      h3 {
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 18px;
      }
      .small {
        font-size: 14px;
        font-weight: 300;
        margin-top: 5px;
        position: relative;
        padding-left: 10px;
        &::after {
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #9c7561;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
        }
      }
    }
  }
  .add-ons {
    h2 {
      font-size: 32px;
      margin-bottom: 6px;
      margin-top: 32px;
    }
    & > p {
      font-size: 14px;
      font-weight: 300;
      color: #64666b;
      margin-bottom: 24px;
    }
    h4 {
      font-size: 20px;
      margin-top: 16px;
      font-family: Inter, sans-serif;
      color: #3e3935;
      font-weight: 500;
    }
    .css-ahj2mt-MuiTypography-root {
      font-family: Inter, sans-serif;
      color: #3e3935;
    }
    textarea {
      max-width: 100%;
      height: 120px;
      width: 100%;
      resize: none;
      font-family: inherit;
      margin-top: 8px;
      padding: 16px;
      /* background: #eff2f6; */
      background: #fff;
      border-radius: 8px;
      border-color: #e3e5ea;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
  .solo {
    border-radius: 8px !important;
  }
  .info {
    margin-top: 32px;
    padding: 24px 0 5px 0;
    border: 1px solid #e3e5ea;
    border-radius: 8px;
    /* background: #eff2f6; */
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    & > div {
      padding: 0 24px 24px;
      & > p {
        font-size: 16px;
        margin-bottom: 24px;
      }
      & > div > p {
        font-size: 16px;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      & > div {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        img {
          max-width: 20px;
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
      &:first-of-type {
        border-bottom: 1px solid #e3e5ea;
      }
      &:last-of-type {
        padding-top: 24px;
      }
    }
  }
`;

export const Fixed = styled.div`
  position: fixed;
  bottom: 0;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 4px 0 16px 0 rgba(0, 0, 0, 0.05);
  z-index: 102;
  background: #fff;
  @media (max-width: 768px) {
    padding: 10px 15px;
  }
  & > div {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    h3 {
      font-size: 24px;
      font-family: Inter, sans-serif;
      @media (max-width: 768px) {
        font-size: 20px;
      }
      span {
        font-weight: 600;
      }
    }
    & > div:last-of-type {
      display: flex;
      align-items: center;
      gap: 32px;
    }
    a {
      font-size: 16px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    button {
      padding: 13px 48px;
      background: red;
      border-radius: 8px;
      color: #fff;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(156, 117, 97, 1) 50%,
        rgba(97, 71, 58, 1) 100%
      );
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 0.3s ease-in-out;
      font-size: 18px;
      border: none;
      outline: none;
      cursor: pointer;
      @media (max-width: 768px) {
        padding: 13px 35px;
        font-size: 16px;
      }
      &.disabled {
        cursor: not-allowed;
        background: #e5e5e5 !important;
        color: #ababab !important;

        &:hover {
          transform: none !important;
        }
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;

export const WithReturn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto;
  border: 2px solid #9c7561;
  width: fit-content;
  border-radius: 10px;
  div {
    padding: 12px 32px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
    &:not(.active) {
      &:hover {
        color: #9c7561;
      }
    }
    &.active {
      color: #fff;
      background: #9c7561;
      border-radius: 7px;
    }
  }
`;

export const Loader = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    color: #9c7561 !important;
  }
`;

export const Step2 = styled.div`
  margin-top: 32px;
  h2 {
    font-size: 24px;
    font-weight: 400 !important;
    font-family: Inter, sans-serif;
    margin-bottom: 4px;
  }
  p {
    font-size: 14px;
  }
  & > div {
    margin-top: 32px;
  }
  .infos {
    margin-bottom: 6px;
    color: #64666b;
    font-weight: 300;
  }
  .input_custom {
    position: relative;
    margin-bottom: 12px;
    & > img {
      max-width: 20px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 8px;
    }
    & > div {
      position: relative;
      img {
        max-width: 20px;
        position: absolute;
        &:first-child {
          left: 32px;
          top: 23.5px;
        }
        &:last-child {
          left: 82px;
          top: 23px;
        }
      }
    }
    p {
      position: absolute;
      top: 6px;
      color: #3e3935;
      left: 16px;
      font-size: 14px;
      font-weight: 600;
    }
    input,
    textarea {
      padding: 29px 15px 6px 15px;
      background: #eff2f6;
      border: 1px solid #f1f2f7;
      width: 100%;
      border-radius: 6px;
      transition: 0.3s ease-in-out;
      resize: none;
      font-family: inherit;
      font-size: 16px;
      &:focus {
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem #0d6efd40;
        color: var(--bs-body-color);
        outline: 0;
      }
    }
    textarea {
      height: 100px;
    }
    .persons {
      padding-left: 60px;
    }
  }
  .phone {
    input {
      padding-left: 60px !important;
    }
    .PhoneInputCountry {
      position: absolute;
      left: 17px;
      bottom: 8px;
      .PhoneInputCountryIcon--border {
        box-shadow: none;
        background: none;
        img {
          position: static !important;
        }
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    & > div {
      max-width: calc(50% - 6px);
      width: 100%;
    }
  }
`;

export const Step3 = styled.div`
  margin-top: 32px;
  h2 {
    margin-bottom: 24px;
  }
  & > div {
    margin-bottom: 16px;
    padding: 16px 24px;
    border-radius: 8px;
    background: #eff2f6;
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px;
      & > div {
        max-width: calc(50% - 12px);
        width: 100%;
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
    .mobile_row {
      & > div {
        max-width: calc(50% - 12px);
        width: 100%;
        @media (max-width: 768px) {
          max-width: calc(50% - 12px);
        }
      }
    }
    .mobile_column {
      @media (max-width: 768px) {
        & > div {
          max-width: 100%;
        }
      }
    }
    & > div {
      margin-bottom: 16px;
      h4 {
        font-weight: 500;
        font-family: 'Inter';
        margin-bottom: 4px;
        font-size: 18px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 300;
        display: flex;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        img {
          max-width: 20px;
        }
      }
    }
  }
`;

export const Step4 = styled.div`
  margin-top: 32px;
  h2 {
    font-size: 24px;
    font-weight: 400 !important;
    font-family: Inter, sans-serif;
    margin-bottom: 8px;
  }
  & > p {
    margin-top: 8px;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  h4 {
    text-align: center;
    margin-top: 18px;
    font-size: 20px;
  }
  .paypal_btn {
    margin-top: 24px;
    & > p {
      margin-bottom: 16px;
      font-weight: 300;
      color: #9c7561;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        max-width: 20px;
      }
    }
  }
  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: -16px;
    & > div {
      max-width: 49%;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        max-width: 150px;
        @media (max-width: 600px) {
          max-width: 100px;
        }
      }
    }
  }

  .selection {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > p {
      margin-top: 16px;
      padding: 8px 0;
      max-width: 49%;
      width: 100%;
      border-radius: 30px;
      border: 1.5px solid #9c7561;
      text-align: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &.active {
        background: #9c7561;
        color: #fff;
        font-weight: 500;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }
  .proceed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 42px;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 18px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 15px;
      }
      span {
        font-weight: 600;
        font-size: 18px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
    button {
      padding: 13px 48px;
      border-radius: 8px;
      color: #fff;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(156, 117, 97, 1) 50%,
        rgba(97, 71, 58, 1) 100%
      );
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 0.3s ease-in-out;
      font-size: 18px;
      border: none;
      outline: none;
      cursor: pointer;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .opacity {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const Step5 = styled.div`
  margin-top: 32px;
  h2 {
    font-size: 28px;
    font-weight: 500 !important;
    font-family: Inter, sans-serif;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    img {
      max-width: 30px;
      margin-right: 10px;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  & > p {
    margin-top: 8px;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  & > img {
    max-width: 100px;
    margin: 32px auto;
    display: flex;
    align-items: center;
    opacity: 0.7;
  }
  button {
    padding: 13px 48px;
    border-radius: 8px;
    color: #fff;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(156, 117, 97, 1) 50%,
      rgba(97, 71, 58, 1) 100%
    );
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px auto;
  }
`;

export const FormPayment = styled.form`
  margin-top: 32px;
  scroll-margin-top: 140px;
  scroll-padding-top: 140px;
  min-height: 300px;
  button {
    width: 100%;
    background: #9c7561;
    margin-top: 8px;
    padding: 10px 8px;
    border-radius: 8px;
    outline: none;
    border: none;
    color: #fff;
    transition: 0.3s ease-in-out;
    &:hover {
      background: #61473a;
    }
  }
  & > p {
    margin-bottom: 16px;
    font-weight: 300;
    color: #9c7561;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      max-width: 20px;
    }
  }
`;
