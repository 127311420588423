import styled from 'styled-components';

export const Destinations = styled.div`
  background-color: #f1f2f7;
  max-width: 100%;
  padding: 72px 0;
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    @media (max-width: 1220px) {
      max-width: 100%;
      padding: 0 15px;
    }
    .top {
      display: flex;
      align-items: stretch;
      gap: 16px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      & > div:first-of-type {
        max-width: 50%;
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        & > div {
          max-width: calc(50% - 8px);
          width: 100%;
          position: relative;
          border: 1px solid #e3e5ea;
          border-radius: 7px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          @media (max-width: 768px) {
            max-width: 100%;
          }
          a {
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 7px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 48px;
            color: #fff;
            font-weight: 500;
            font-size: 16px;
            opacity: 0;
            transition: 0.3s ease-in-out;
          }
          &:hover {
            a {
              opacity: 1;
            }
          }
          & > div {
            padding: 7px 8px 8px;
            border-top: 1px solid #e3e5ea;
            margin-top: -4px;
            background: #fff;
            border-radius: 0 0 7px 7px;
            position: relative;
            svg {
              max-width: 25px;
              position: absolute;
              top: 5px;
              right: 10px;
            }
            p {
              position: relative;
              padding-left: 10px;
              font-size: 14px;
              &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                background: #9c7561;
                width: 4px;
                height: 4px;
                border-radius: 50%;
              }
            }
            span {
              font-weight: 600;
            }
          }
          h3 {
            color: #61473a;
            margin-bottom: 4px;
          }
          img {
            width: 100%;
            border-radius: 6px 6px 0 0;
            height: 186px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      & > div:last-of-type {
        max-width: 50%;
        width: 100%;
        height: auto;
        position: relative;
        border: 1px solid #e3e5ea;
        border-radius: 7px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        a {
          height: 100%;
          position: absolute;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 7px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 48px;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
          opacity: 0;
          transition: 0.3s ease-in-out;
        }
        &:hover {
          a {
            opacity: 1;
          }
        }
        & > div {
          background: #fff;
          border-radius: 0 0 7px 7px;
          padding: 7px 8px 8px;
          border-top: 1px solid #e3e5ea;
          margin-top: -4px;
          position: relative;
          svg {
            max-width: 40px;
            position: absolute;
            top: 7px;
            right: 10px;
          }
        }
        h3 {
          color: #61473a;
          margin-bottom: 4px;
        }
        img {
          max-width: 100%;
          border-radius: 6px 6px 0 0;
          height: calc(100% - 60.5px);
          object-fit: cover;
          object-position: center;
          @media (max-width: 768px) {
            height: 250px;
            width: 100%;
          }
        }
        p {
          position: relative;
          padding-left: 10px;
          font-size: 14px;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            background: #9c7561;
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }
        }
        span {
          font-weight: 600;
        }
      }
    }
    .bottom {
      width: 100%;
      position: relative;
      border: 1px solid #e3e5ea;
      border-radius: 7px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin-top: 16px;
      background: #fff;
      padding: 25px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 768px) {
        max-width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 15px;
      }
      p {
        font-size: 14px;
        margin-top: 4px;
      }
      a {
        padding: 15px 16px;
        background: red;
        border-radius: 8px;
        color: #fff;
        background: rgb(2, 0, 36);
        background: linear-gradient(
          90deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(156, 117, 97, 1) 50%,
          rgba(97, 71, 58, 1) 100%
        );
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: rotate(3deg);
        }
      }
    }
  }
  h2 {
    font-size: 42px;
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 32px;
      text-align: center;
    }
  }
`;
