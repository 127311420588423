import styled from 'styled-components';

export const Footer = styled.div`
  background: linear-gradient(
    90deg,
    rgba(9, 8, 7, 1) 0%,
    rgba(56, 43, 37, 1) 52%,
    rgba(29, 24, 24, 1) 100%,
    rgba(156, 117, 97, 1) 100%,
    rgba(97, 71, 58, 1) 100%
  );
  padding: 60px 0;
  @media (max-width: 768px) {
    padding: 32px 0;
  }
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    @media (max-width: 1220px) {
      padding: 0 15px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
    & > div:first-child {
      max-width: 45%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      img {
        max-width: 130px;
        margin-bottom: 12px;
      }
      p {
        font-size: 16px;
        color: #fff;
        line-height: 24px;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      max-width: 15%;
      width: 100%;
      margin-left: auto;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      h5 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 28px;
      }
      a {
        font-size: 14px;
        color: #fff;
        margin-bottom: 8px;
      }
    }
    & > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      max-width: 25%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      h5 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 12px;
      }
      svg {
        max-width: 25px;
      }
      & > div {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;
        h6 {
          font-weight: 500;
          color: #fff;
          font-size: 16px;
          margin-bottom: 4px;
        }
        p {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
`;
