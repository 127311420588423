import styled, { keyframes } from 'styled-components';

export const Header = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05),
    0 -1px 10px 0 rgba(0, 0, 0, 0.01);
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 15px;
  width: 100%;
  z-index: 9;
  @media (max-width: 768px) {
    padding: 15px 25px;
  }
  & > div {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
      gap: 24px;
      a {
        font-size: 18px;
        font-family: Circularpro book, Inter, sans-serif;
        font-weight: 400;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          width: 0;
          height: 2px;
          bottom: -4px;
          background: #9c7561;
          z-index: 1;
          left: 0;
          transition: 0.4s ease-in-out;
        }
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
    img {
      max-width: 180px;
      cursor: pointer;
      @media (max-width: 768px) {
        max-width: 130px;
        margin-left: -10px;
      }
    }
  }
  .css-1tei9w9 {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: normal;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: var(--chakra-lineHeights-7);
    border-radius: var(--chakra-radii-md);
    font-weight: var(--chakra-fontWeights-semibold);
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    height: auto;
    min-width: var(--chakra-sizes-10);
    font-size: 20px;
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    padding: 0px;
    text-align: left;
    color: var(--chakra-colors-purple-500);
    background-color: var(--chakra-colors-transparent);
    border: none;
    margin-bottom: -14px;
    width: 30px;
    height: 30px;
    margin-left: -6px;
  }
  .css-1tei9w9:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: var(--chakra-shadows-none);
  }
  .css-1tei9w9:hover:disabled {
    background: initial;
  }
  .css-1wh2kri {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
  }
  .css-1pvzqor {
    position: relative;
    width: 30px;
    height: 3px;
    border-top-style: solid;
    border-color: var(--chakra-colors-violet-500);
    border-width: 0px;
    background-color: var(--chakra-colors-transparent);
    transition: border-width 0.1s linear 0s;
    transform: translateY(-10px);
  }
  .css-1pvzqor::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    border-top-width: 2px;
    border-top-style: solid;
    border-color: var(--chakra-colors-violet-500);
    transition: transform 0.2s linear 0s;
    transform: rotate(-45deg) translateX(-5px) translateY(6px);
  }
  .css-1pvzqor::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    border-top-width: 2px;
    border-top-style: solid;
    border-color: var(--chakra-colors-violet-500);
    transition: transform 0.2s linear 0s;
    transform: rotate(45deg) translateX(2px) translateY(-6px);
    transform-origin: left center;
  }
  .css-za1uc2 {
    position: relative;
    width: 33px;
    height: 3px;
    border: var(--chakra-borders-none);
    border-top: solid;
    border-color: var(--chakra-colors-violet-500);
    border-width: 2px;
    background-color: var(--chakra-colors-transparent);
    -webkit-transition: border-width 0.1s linear;
    transition: border-width 0.1s linear;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  .css-za1uc2::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 33px;
    border-top: 2px solid;
    border-color: var(--chakra-colors-violet-500);
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    -webkit-transform: translateY(4px);
    -moz-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
  }
  .css-za1uc2::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    border-top: 2px solid;
    border-color: var(--chakra-colors-violet-500);
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    transform-origin: left;
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Mobiledev = styled.div`
  width: 100%;
  position: fixed;
  height: calc(100vh - 67px);
  background: #fff;
  top: 67px;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  animation: ${slideInFromRight} 0.3s ease-out forwards;
  & > div:first-of-type {
    padding: 48px 25px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    a {
      font-size: 28px;
    }
  }
  .bottom {
    margin-top: auto;
    padding: 24px 25px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-top: 1px solid #e3e5ea;
    h6 {
      font-size: 18px;
      margin-bottom: 6px;
    }
    p {
      font-size: 14px;
    }
  }
`;
