import styled from 'styled-components';

export const Hero = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 82px;
  background: url('/img/hero.webp');
  background-size: cover;
  position: relative;
  height: 100vh;
  min-height: 800px;
  overflow: hidden;
  @media (max-width: 600px) {
    background-position: -1000px;
    height: fit-content;
    .min_height {
      min-height: 108px;
    }
  }
  & > img {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.6;
    top: -400px;
    z-index: 1;
    @media (max-width: 600px) {
      top: 0;
      height: 300px;
    }
  }
  .reversed {
    bottom: -300px;
    top: unset;
    transform: rotate(-180deg);
    @media (max-width: 768px) {
      bottom: 0px;
    }
  }
  & > div:first-of-type {
    max-width: 1170px;
    margin: 0 auto;
    padding-top: 42px;
    z-index: 3;
    position: relative;
    @media (max-width: 1230px) {
      padding: 42px 15px 0 15px;
    }
    h1 {
      color: #fff;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    p {
      color: #fff;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
`;
