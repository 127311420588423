import { useState, useEffect } from 'react';
import * as S from './styles';
import axios from 'axios';
import OutsideClickHandler from 'react-outside-click-handler';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import moment from 'moment/moment';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { setAlert } from '../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import { setData, setIsByHour } from '../../slices/paramsSlice';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';

const pastMonth = new Date(2020, 10, 15);

const MainBlock = () => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [selectTo, setSelectedTo] = useState('');
  const [selectFrom, setSelectedFrom] = useState(false);
  const [openFromOptions, setOpenFromOptions] = useState(false);
  const [opentoOptions, setOpenToOptions] = useState(false);
  const [fromFull, setFromFull] = useState({});
  const [toFull, setToFull] = useState({});

  const [fromReturn, setFromReturn] = useState('');
  const [toReturn, setToReturn] = useState('');
  const [fromOptionsReturn, setFromOptionsReturn] = useState([]);
  const [toOptionsReturn, setToOptionsReturn] = useState([]);
  const [selectToReturn, setSelectedToReturn] = useState('');
  const [selectFromReturn, setSelectedFromReturn] = useState(false);
  const [openFromOptionsReturn, setOpenFromOptionsReturn] = useState(false);
  const [opentoOptionsReturn, setOpenToOptionsReturn] = useState(false);
  const [fromFullReturn, setFromFullReturn] = useState({});
  const [toFullReturn, setToFullReturn] = useState({});

  const [selectedHours, setSelectedHours] = useState({
    value: 3,
    label: '3 Hours',
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showTimeReturn, setShowTimeReturn] = useState(false);

  const [customRequest, setCustomRequest] = useState({ phone: '' });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [activeTab, setActiveTab] = useState('one_way');

  const history = useHistory();
  const location = useLocation();
  console.log(location);
  useEffect(() => {
    if (location?.state?.from === 'custom_request') {
      setActiveTab('custom');
    } else if (location?.search === '?hour') {
      setActiveTab('hour');
    }
  }, [location?.state]);

  const submit = () => {
    dispatch(
      setData({
        fromFull,
        toFull,
        fromFullReturn: fromFullReturn || false,
        toFullReturn: toFullReturn || false,
        persons: persons,
        time: time,
        timeReturn: timeReturn,
        range: range,
        tab: activeTab,
        hour: selectedHours.value,
      })
    );
    history.push('/booking');
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchSuggestions = async () => {
    const url = `https://proxy-is-indeed-proxy.onrender.com/https://www.blacklane.com/api/graphql/public/`;
    try {
      const response = await axios.post(url, {
        operationName: 'locationsAutocomplete',
        variables: {
          location: from,
          locale: 'en',
        },
        query:
          'query locationsAutocomplete($location: String!, $locale: AllowedLocales, $biasedPlaceId: String) {\n  locationsAutocomplete(\n    input: $location\n    locale: $locale\n    overExternal: true\n    biasedPlaceId: $biasedPlaceId\n  ) {\n    address\n    addressWithMeetingPoint\n    airportIata\n    latitude\n    longitude\n    meetingPoint\n    name\n    placeId\n    types\n    id\n    timezone\n    __typename\n  }\n}',
      });

      setFromOptions(response.data?.data?.locationsAutocomplete);
      setOpenFromOptions(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSuggestionsTo = async () => {
    const url = `https://proxy-is-indeed-proxy.onrender.com/https://www.blacklane.com/api/graphql/public/`;
    try {
      const response = await axios.post(url, {
        operationName: 'locationsAutocomplete',
        variables: {
          location: to,
          locale: 'en',
        },
        query:
          'query locationsAutocomplete($location: String!, $locale: AllowedLocales, $biasedPlaceId: String) {\n  locationsAutocomplete(\n    input: $location\n    locale: $locale\n    overExternal: true\n    biasedPlaceId: $biasedPlaceId\n  ) {\n    address\n    addressWithMeetingPoint\n    airportIata\n    latitude\n    longitude\n    meetingPoint\n    name\n    placeId\n    types\n    id\n    timezone\n    __typename\n  }\n}',
      });

      setToOptions(response.data?.data?.locationsAutocomplete);
      setOpenToOptions(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSuggestionsReturn = async () => {
    const url = `https://proxy-is-indeed-proxy.onrender.com/https://www.blacklane.com/api/graphql/public/`;
    try {
      const response = await axios.post(url, {
        operationName: 'locationsAutocomplete',
        variables: {
          location: fromReturn,
          locale: 'en',
        },
        query:
          'query locationsAutocomplete($location: String!, $locale: AllowedLocales, $biasedPlaceId: String) {\n  locationsAutocomplete(\n    input: $location\n    locale: $locale\n    overExternal: true\n    biasedPlaceId: $biasedPlaceId\n  ) {\n    address\n    addressWithMeetingPoint\n    airportIata\n    latitude\n    longitude\n    meetingPoint\n    name\n    placeId\n    types\n    id\n    timezone\n    __typename\n  }\n}',
      });

      setFromOptionsReturn(response.data?.data?.locationsAutocomplete);
      setOpenFromOptionsReturn(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSuggestionsToReturn = async () => {
    const url = `https://proxy-is-indeed-proxy.onrender.com/https://www.blacklane.com/api/graphql/public/`;
    try {
      const response = await axios.post(url, {
        operationName: 'locationsAutocomplete',
        variables: {
          location: toReturn,
          locale: 'en',
        },
        query:
          'query locationsAutocomplete($location: String!, $locale: AllowedLocales, $biasedPlaceId: String) {\n  locationsAutocomplete(\n    input: $location\n    locale: $locale\n    overExternal: true\n    biasedPlaceId: $biasedPlaceId\n  ) {\n    address\n    addressWithMeetingPoint\n    airportIata\n    latitude\n    longitude\n    meetingPoint\n    name\n    placeId\n    types\n    id\n    timezone\n    __typename\n  }\n}',
      });
      setToOptionsReturn(response.data?.data?.locationsAutocomplete);
      setOpenToOptionsReturn(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    !selectFrom && fetchSuggestions();
  }, [from]);

  useEffect(() => {
    !selectTo && fetchSuggestionsTo();
  }, [to]);

  useEffect(() => {
    !selectFromReturn && fetchSuggestionsReturn();
  }, [fromReturn]);

  useEffect(() => {
    !selectToReturn && fetchSuggestionsToReturn();
  }, [toReturn]);

  const [range, setRange] = useState();

  useEffect(() => {
    if (activeTab === 'one_way' || activeTab === 'hour') {
      range && setShowCalendar(false);
    } else {
      if (range && Object.keys(range)?.length > 1) {
        if (range.to) setShowCalendar(false);
      }
    }
  }, [range]);

  useEffect(() => {
    setRange();
  }, [activeTab]);

  const [time, setTime] = useState(dayjs('2022-04-17T20:30'));
  const [timeReturn, setTimeReturn] = useState(dayjs('2022-04-17T20:30'));
  const [persons, setPersons] = useState(1);

  const validateEmail = (val) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val);
  };

  const dispatch = useDispatch();

  const validateCustomRequest = () => {
    if (
      customRequest.first_name &&
      customRequest?.last_name &&
      customRequest?.email &&
      customRequest?.message &&
      customRequest?.phone
    ) {
      if (validateEmail(customRequest?.email)) {
        axios
          .post('https://submit-form.com/pDoplxfih', {
            'First Name': customRequest.first_name,
            'Last Name': customRequest?.last_name,
            Email: customRequest?.email,
            'Phone Number': customRequest?.phone,
            Message: customRequest?.message,
          })
          .then((res) => {
            axios
              .post(`${process.env.REACT_APP_API_URL}/custom_request`, {
                first_name: customRequest.first_name,
                last_name: customRequest?.last_name,
                email: customRequest?.email,
                phone: customRequest?.phone,
                message: customRequest?.message,
                date_created: new Date(),
              })
              .then((res) => {})
              .catch((err) => console.log(err));
            dispatch(
              setAlert({
                message: 'Request was submitted successfully!',
                style: 'success',
              })
            );
            setCustomRequest({
              first_name: '',
              last_name: '',
              email: '',
              message: '',
              phone: '',
            });
          })
          .catch((err) => console.log(err));
      } else {
        dispatch(setAlert({ message: 'The Email provided is not valid' }));
      }
    } else {
      dispatch(setAlert({ message: 'Please fulfill all required fields.' }));
    }
  };

  const disabledDates = [
    new Date(new Date().getFullYear(), 11, 25), // December 25th of the current year
    new Date(new Date().getFullYear(), 7, 15), // August 15th of the current year
    new Date(new Date().getFullYear(), 11, 31), // December 31st of the current year
  ];

  console.log(from, selectFrom);

  const validateOneWay = () => {
    if (
      (to && selectTo && from && selectFrom) ||
      (from && selectFrom && activeTab === 'hour')
    ) {
      if (range) {
        if (time) {
          submit();
        } else {
          dispatch(setAlert({ message: 'Please specify the Time.' }));
        }
      } else {
        dispatch(setAlert({ message: 'Please specify the Date.' }));
      }
    } else {
      dispatch(setAlert({ message: 'Please select a Destination.' }));
    }
  };
  const validateReturn = () => {
    if (to && selectTo && from && selectFrom) {
      if (toReturn && selectToReturn && fromReturn && selectFromReturn) {
        if (range?.to && range?.from) {
          if (time) {
            if (timeReturn) {
              submit();
            } else {
              dispatch(
                setAlert({ message: 'Please specify the Return Time.' })
              );
            }
          } else {
            dispatch(setAlert({ message: 'Please specify the Time.' }));
          }
        } else {
          dispatch(setAlert({ message: 'Please specify the Date.' }));
        }
      } else {
        dispatch(setAlert({ message: 'Please select a Return Destination.' }));
      }
    } else {
      dispatch(setAlert({ message: 'Please select a Destination.' }));
    }
  };

  const today = new Date();

  return (
    <S.MainBlock
      isReturn={activeTab === 'with_return' || activeTab === 'custom'}>
      <div className='options'>
        <p
          className={activeTab === 'one_way' ? 'active' : ''}
          onClick={() => {
            setActiveTab('one_way');
            dispatch(setIsByHour(false));
          }}>
          One Way
        </p>
        <p
          className={activeTab === 'with_return' ? 'active' : ''}
          onClick={() => {
            setActiveTab('with_return');
            dispatch(setIsByHour(false));
          }}>
          With Return
        </p>
        <p
          className={activeTab === 'hour' ? 'active' : ''}
          onClick={() => {
            setActiveTab('hour');
            dispatch(setIsByHour(true));
          }}>
          By The Hour
        </p>
        <p
          className={activeTab === 'custom' ? 'active' : ''}
          onClick={() => {
            setActiveTab('custom');
            dispatch(setIsByHour(false));
          }}>
          Custom Request
        </p>
      </div>
      <div className='inputs'>
        {activeTab !== 'custom' ? (
          <>
            {activeTab === 'with_return' ? (
              <>
                <div className='row'>
                  <div className='input_custom'>
                    <img src='/img/pin.svg' alt='Pin' />
                    <input
                      type='text'
                      placeholder='Address, airport, hotel...'
                      value={from}
                      onChange={(e) => {
                        setFrom(e.target.value);
                        setSelectedFrom(false);
                      }}
                      onClick={() => setOpenFromOptions(true)}
                    />
                    <p>From</p>
                    {fromOptions?.length >= 1 &&
                      openFromOptions &&
                      !selectFrom && (
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setOpenFromOptions(false);
                          }}>
                          <div className='results'>
                            {fromOptions.map((elem, i) => (
                              <div
                                onClick={() => {
                                  setOpenFromOptions(false);
                                  setFrom(elem?.name.split(',')?.[0]);
                                  setSelectedFrom(true);
                                  setFromFull(elem);
                                }}>
                                {elem?.types?.[0] === 'airport' ? (
                                  <svg
                                    fill='#61473a'
                                    height='25px'
                                    width='64px'
                                    version='1.1'
                                    id='Layer_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    viewBox='0 0 122.88 122.88'
                                    xmlSpace='preserve'
                                    className='via'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <style
                                        type='text/css'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                        }}
                                      />{' '}
                                      <g>
                                        {' '}
                                        <path
                                          className='st0'
                                          d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                        />{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                ) : elem?.types?.[0] === 'hotel' ? (
                                  <svg
                                    fill='#000000'
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    id='hotel-3'
                                    data-name='Line Color'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='icon line-color'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      <rect
                                        id='secondary'
                                        x={12}
                                        y={15}
                                        width={4}
                                        height={6}
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                      <path
                                        id='primary'
                                        d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                    </g>
                                  </svg>
                                ) : elem?.types?.[0] === 'station' ? (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <path
                                        d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                        stroke='#61473a'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />{' '}
                                    </g>
                                  </svg>
                                ) : (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='-3 0 20 20'
                                    version='1.1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    fill='#000000'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <title>
                                        pin_sharp_circle [#624]
                                      </title>{' '}
                                      <desc>Created with Sketch.</desc>{' '}
                                      <defs> </defs>{' '}
                                      <g
                                        id='Page-1'
                                        stroke='none'
                                        strokeWidth={1}
                                        fill='none'
                                        fillRule='evenodd'>
                                        {' '}
                                        <g
                                          id='Dribbble-Light-Preview'
                                          transform='translate(-223.000000, -5439.000000)'
                                          fill='#61473a'>
                                          {' '}
                                          <g
                                            id='icons'
                                            transform='translate(56.000000, 160.000000)'>
                                            {' '}
                                            <path
                                              d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                              id='pin_sharp_circle-[#624]'>
                                              {' '}
                                            </path>{' '}
                                          </g>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                )}

                                <p>{elem?.name.split(',')?.[0]}</p>
                                <p className='small'>{elem?.address}</p>
                              </div>
                            ))}
                          </div>
                        </OutsideClickHandler>
                      )}
                  </div>
                  <div className='input_custom'>
                    <img src='/img/pin.svg' alt='Pin' />
                    <input
                      type='text'
                      placeholder='Address, airport, hotel...'
                      onChange={(e) => {
                        setTo(e.target.value);
                        setSelectedTo(false);
                      }}
                      value={to}
                      onClick={() => setOpenToOptions(true)}
                    />
                    <p>To</p>
                    {toOptions?.length >= 1 && opentoOptions && !selectTo && (
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setOpenToOptions(false);
                        }}>
                        <div className='results results_mobile'>
                          {toOptions.map((elem, i) => (
                            <div
                              onClick={() => {
                                setOpenToOptions(false);
                                setTo(elem?.name.split(',')?.[0]);
                                setSelectedTo(true);
                                setToFull(elem);
                              }}>
                              {elem?.types?.[0] === 'airport' ? (
                                <svg
                                  fill='#61473a'
                                  height='25px'
                                  width='64px'
                                  version='1.1'
                                  id='Layer_1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                  viewBox='0 0 122.88 122.88'
                                  xmlSpace='preserve'
                                  className='via'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <style
                                      type='text/css'
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                      }}
                                    />{' '}
                                    <g>
                                      {' '}
                                      <path
                                        className='st0'
                                        d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                      />{' '}
                                    </g>{' '}
                                  </g>
                                </svg>
                              ) : elem?.types?.[0] === 'hotel' ? (
                                <svg
                                  fill='#000000'
                                  width='64px'
                                  height='25px'
                                  viewBox='0 0 24 24'
                                  id='hotel-3'
                                  data-name='Line Color'
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='icon line-color'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    <rect
                                      id='secondary'
                                      x={12}
                                      y={15}
                                      width={4}
                                      height={6}
                                      style={{
                                        fill: 'none',
                                        stroke: '#61473a',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: 2,
                                      }}
                                    />
                                    <path
                                      id='primary'
                                      d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                      style={{
                                        fill: 'none',
                                        stroke: '#61473a',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: 2,
                                      }}
                                    />
                                  </g>
                                </svg>
                              ) : elem?.types?.[0] === 'station' ? (
                                <svg
                                  width='64px'
                                  height='25px'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <path
                                      d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                      stroke='#61473a'
                                      strokeWidth={2}
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />{' '}
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  width='64px'
                                  height='25px'
                                  viewBox='-3 0 20 20'
                                  version='1.1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                  fill='#000000'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <title>pin_sharp_circle [#624]</title>{' '}
                                    <desc>Created with Sketch.</desc>{' '}
                                    <defs> </defs>{' '}
                                    <g
                                      id='Page-1'
                                      stroke='none'
                                      strokeWidth={1}
                                      fill='none'
                                      fillRule='evenodd'>
                                      {' '}
                                      <g
                                        id='Dribbble-Light-Preview'
                                        transform='translate(-223.000000, -5439.000000)'
                                        fill='#61473a'>
                                        {' '}
                                        <g
                                          id='icons'
                                          transform='translate(56.000000, 160.000000)'>
                                          {' '}
                                          <path
                                            d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                            id='pin_sharp_circle-[#624]'>
                                            {' '}
                                          </path>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>
                                </svg>
                              )}

                              <p>{elem?.name.split(',')?.[0]}</p>
                              <p className='small'>{elem?.address}</p>
                            </div>
                          ))}
                        </div>
                      </OutsideClickHandler>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='input_custom'>
                    <img src='/img/pin.svg' alt='Pin' />
                    <input
                      type='text'
                      placeholder='Address, airport, hotel...'
                      value={fromReturn}
                      onChange={(e) => {
                        setFromReturn(e.target.value);
                        setSelectedFromReturn(false);
                      }}
                      onClick={() => setOpenFromOptionsReturn(true)}
                    />
                    <p>Return From</p>
                    {fromOptionsReturn?.length >= 1 &&
                      openFromOptionsReturn &&
                      !selectFromReturn && (
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setOpenFromOptionsReturn(false);
                          }}>
                          <div className='results'>
                            {fromOptionsReturn.map((elem, i) => (
                              <div
                                onClick={() => {
                                  setOpenFromOptionsReturn(false);
                                  setFromReturn(elem?.name.split(',')?.[0]);
                                  setSelectedFromReturn(true);
                                  setFromFullReturn(elem);
                                }}>
                                {elem?.types?.[0] === 'airport' ? (
                                  <svg
                                    fill='#61473a'
                                    height='25px'
                                    width='64px'
                                    version='1.1'
                                    id='Layer_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    viewBox='0 0 122.88 122.88'
                                    xmlSpace='preserve'
                                    className='via'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <style
                                        type='text/css'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                        }}
                                      />{' '}
                                      <g>
                                        {' '}
                                        <path
                                          className='st0'
                                          d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                        />{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                ) : elem?.types?.[0] === 'hotel' ? (
                                  <svg
                                    fill='#000000'
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    id='hotel-3'
                                    data-name='Line Color'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='icon line-color'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      <rect
                                        id='secondary'
                                        x={12}
                                        y={15}
                                        width={4}
                                        height={6}
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                      <path
                                        id='primary'
                                        d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                    </g>
                                  </svg>
                                ) : elem?.types?.[0] === 'station' ? (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <path
                                        d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                        stroke='#61473a'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />{' '}
                                    </g>
                                  </svg>
                                ) : (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='-3 0 20 20'
                                    version='1.1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    fill='#000000'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <title>
                                        pin_sharp_circle [#624]
                                      </title>{' '}
                                      <desc>Created with Sketch.</desc>{' '}
                                      <defs> </defs>{' '}
                                      <g
                                        id='Page-1'
                                        stroke='none'
                                        strokeWidth={1}
                                        fill='none'
                                        fillRule='evenodd'>
                                        {' '}
                                        <g
                                          id='Dribbble-Light-Preview'
                                          transform='translate(-223.000000, -5439.000000)'
                                          fill='#61473a'>
                                          {' '}
                                          <g
                                            id='icons'
                                            transform='translate(56.000000, 160.000000)'>
                                            {' '}
                                            <path
                                              d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                              id='pin_sharp_circle-[#624]'>
                                              {' '}
                                            </path>{' '}
                                          </g>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                )}

                                <p>{elem?.name.split(',')?.[0]}</p>
                                <p className='small'>{elem?.address}</p>
                              </div>
                            ))}
                          </div>
                        </OutsideClickHandler>
                      )}
                  </div>
                  <div className='input_custom'>
                    <img src='/img/pin.svg' alt='Pin' />
                    <input
                      type='text'
                      placeholder='Address, airport, hotel...'
                      onChange={(e) => {
                        setToReturn(e.target.value);
                        setSelectedToReturn(false);
                      }}
                      value={toReturn}
                      onClick={() => setOpenToOptionsReturn(true)}
                    />
                    <p>Return To</p>
                    {toOptionsReturn?.length >= 1 &&
                      opentoOptionsReturn &&
                      !selectToReturn && (
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setOpenToOptionsReturn(false);
                          }}>
                          <div className='results results_mobile'>
                            {toOptionsReturn.map((elem, i) => (
                              <div
                                onClick={() => {
                                  setOpenToOptionsReturn(false);
                                  setToReturn(elem?.name.split(',')?.[0]);
                                  setSelectedToReturn(true);
                                  setToFullReturn(elem);
                                }}>
                                {elem?.types?.[0] === 'airport' ? (
                                  <svg
                                    fill='#61473a'
                                    height='25px'
                                    width='64px'
                                    version='1.1'
                                    id='Layer_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    viewBox='0 0 122.88 122.88'
                                    xmlSpace='preserve'
                                    className='via'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <style
                                        type='text/css'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                        }}
                                      />{' '}
                                      <g>
                                        {' '}
                                        <path
                                          className='st0'
                                          d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                        />{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                ) : elem?.types?.[0] === 'hotel' ? (
                                  <svg
                                    fill='#000000'
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    id='hotel-3'
                                    data-name='Line Color'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='icon line-color'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      <rect
                                        id='secondary'
                                        x={12}
                                        y={15}
                                        width={4}
                                        height={6}
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                      <path
                                        id='primary'
                                        d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                        style={{
                                          fill: 'none',
                                          stroke: '#61473a',
                                          strokeLinecap: 'round',
                                          strokeLinejoin: 'round',
                                          strokeWidth: 2,
                                        }}
                                      />
                                    </g>
                                  </svg>
                                ) : elem?.types?.[0] === 'station' ? (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <path
                                        d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                        stroke='#61473a'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />{' '}
                                    </g>
                                  </svg>
                                ) : (
                                  <svg
                                    width='64px'
                                    height='25px'
                                    viewBox='-3 0 20 20'
                                    version='1.1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    fill='#000000'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                    <g
                                      id='SVGRepo_tracerCarrier'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <g id='SVGRepo_iconCarrier'>
                                      {' '}
                                      <title>
                                        pin_sharp_circle [#624]
                                      </title>{' '}
                                      <desc>Created with Sketch.</desc>{' '}
                                      <defs> </defs>{' '}
                                      <g
                                        id='Page-1'
                                        stroke='none'
                                        strokeWidth={1}
                                        fill='none'
                                        fillRule='evenodd'>
                                        {' '}
                                        <g
                                          id='Dribbble-Light-Preview'
                                          transform='translate(-223.000000, -5439.000000)'
                                          fill='#61473a'>
                                          {' '}
                                          <g
                                            id='icons'
                                            transform='translate(56.000000, 160.000000)'>
                                            {' '}
                                            <path
                                              d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                              id='pin_sharp_circle-[#624]'>
                                              {' '}
                                            </path>{' '}
                                          </g>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>
                                  </svg>
                                )}

                                <p>{elem?.name.split(',')?.[0]}</p>
                                <p className='small'>{elem?.address}</p>
                              </div>
                            ))}
                          </div>
                        </OutsideClickHandler>
                      )}
                  </div>
                </div>
              </>
            ) : activeTab === 'hour' ? (
              <>
                <div className='input_custom'>
                  <img src='/img/pin.svg' alt='Pin' />
                  <input
                    type='text'
                    placeholder='Address, airport, hotel, ...'
                    value={from}
                    onChange={(e) => {
                      setFrom(e.target.value);
                      setSelectedFrom(false);
                    }}
                    onClick={() => setOpenFromOptions(true)}
                  />
                  <p>From</p>
                  {fromOptions?.length >= 1 &&
                    openFromOptions &&
                    !selectFrom && (
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setOpenFromOptions(false);
                        }}>
                        <div className='results'>
                          {fromOptions.map((elem, i) => (
                            <div
                              onClick={() => {
                                setOpenFromOptions(false);
                                setFrom(elem?.name.split(',')?.[0]);
                                setSelectedFrom(true);
                                setFromFull(elem);
                              }}>
                              {elem?.types?.[0] === 'airport' ? (
                                <svg
                                  fill='#61473a'
                                  height='25px'
                                  width='64px'
                                  version='1.1'
                                  id='Layer_1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                  viewBox='0 0 122.88 122.88'
                                  xmlSpace='preserve'
                                  className='via'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <style
                                      type='text/css'
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                      }}
                                    />{' '}
                                    <g>
                                      {' '}
                                      <path
                                        className='st0'
                                        d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                      />{' '}
                                    </g>{' '}
                                  </g>
                                </svg>
                              ) : elem?.types?.[0] === 'hotel' ? (
                                <svg
                                  fill='#000000'
                                  width='64px'
                                  height='25px'
                                  viewBox='0 0 24 24'
                                  id='hotel-3'
                                  data-name='Line Color'
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='icon line-color'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    <rect
                                      id='secondary'
                                      x={12}
                                      y={15}
                                      width={4}
                                      height={6}
                                      style={{
                                        fill: 'none',
                                        stroke: '#61473a',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: 2,
                                      }}
                                    />
                                    <path
                                      id='primary'
                                      d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                      style={{
                                        fill: 'none',
                                        stroke: '#61473a',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: 2,
                                      }}
                                    />
                                  </g>
                                </svg>
                              ) : elem?.types?.[0] === 'station' ? (
                                <svg
                                  width='64px'
                                  height='25px'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <path
                                      d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                      stroke='#61473a'
                                      strokeWidth={2}
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />{' '}
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  width='64px'
                                  height='25px'
                                  viewBox='-3 0 20 20'
                                  version='1.1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                  fill='#000000'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <title>pin_sharp_circle [#624]</title>{' '}
                                    <desc>Created with Sketch.</desc>{' '}
                                    <defs> </defs>{' '}
                                    <g
                                      id='Page-1'
                                      stroke='none'
                                      strokeWidth={1}
                                      fill='none'
                                      fillRule='evenodd'>
                                      {' '}
                                      <g
                                        id='Dribbble-Light-Preview'
                                        transform='translate(-223.000000, -5439.000000)'
                                        fill='#61473a'>
                                        {' '}
                                        <g
                                          id='icons'
                                          transform='translate(56.000000, 160.000000)'>
                                          {' '}
                                          <path
                                            d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                            id='pin_sharp_circle-[#624]'>
                                            {' '}
                                          </path>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>
                                </svg>
                              )}

                              <p>{elem?.name.split(',')?.[0]}</p>
                              <p className='small'>{elem?.address}</p>
                            </div>
                          ))}
                        </div>
                      </OutsideClickHandler>
                    )}
                </div>
                <div className='input_custom'>
                  <img
                    src='/img/clock-circle-svgrepo-com.svg'
                    alt='Pin'
                    className='hour'
                  />
                  <Select
                    options={[
                      { value: 3, label: '3 Hours' },
                      { value: 4, label: '4 Hours' },
                      { value: 5, label: '5 Hours' },
                      { value: 6, label: '6 Hours' },
                      { value: 7, label: '7 Hours' },
                      { value: 8, label: '8 Hours' },
                      { value: 9, label: '9 Hours' },
                      { value: 10, label: '10 Hours' },
                      { value: 11, label: '11 Hours' },
                      { value: 12, label: '12 Hours' },
                    ]}
                    value={selectedHours}
                    onChange={(o) => setSelectedHours(o)}
                    className='duration'
                  />
                  <p>Duration</p>
                </div>
              </>
            ) : (
              <>
                <div className='input_custom'>
                  <img src='/img/pin.svg' alt='Pin' />
                  <input
                    type='text'
                    placeholder='Address, airport, hotel, ...'
                    value={from}
                    onChange={(e) => {
                      setFrom(e.target.value);
                      setSelectedFrom(false);
                    }}
                    onClick={() => setOpenFromOptions(true)}
                  />
                  <p>From</p>
                  {fromOptions?.length >= 1 &&
                    openFromOptions &&
                    !selectFrom && (
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setOpenFromOptions(false);
                        }}>
                        <div className='results'>
                          {fromOptions.map((elem, i) => (
                            <div
                              onClick={() => {
                                setOpenFromOptions(false);
                                setFrom(elem?.name.split(',')?.[0]);
                                setSelectedFrom(true);
                                setFromFull(elem);
                              }}>
                              {elem?.types?.[0] === 'airport' ? (
                                <svg
                                  fill='#61473a'
                                  height='25px'
                                  width='64px'
                                  version='1.1'
                                  id='Layer_1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                  viewBox='0 0 122.88 122.88'
                                  xmlSpace='preserve'
                                  className='via'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <style
                                      type='text/css'
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                      }}
                                    />{' '}
                                    <g>
                                      {' '}
                                      <path
                                        className='st0'
                                        d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                      />{' '}
                                    </g>{' '}
                                  </g>
                                </svg>
                              ) : elem?.types?.[0] === 'hotel' ? (
                                <svg
                                  fill='#000000'
                                  width='64px'
                                  height='25px'
                                  viewBox='0 0 24 24'
                                  id='hotel-3'
                                  data-name='Line Color'
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='icon line-color'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    <rect
                                      id='secondary'
                                      x={12}
                                      y={15}
                                      width={4}
                                      height={6}
                                      style={{
                                        fill: 'none',
                                        stroke: '#61473a',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: 2,
                                      }}
                                    />
                                    <path
                                      id='primary'
                                      d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                      style={{
                                        fill: 'none',
                                        stroke: '#61473a',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: 2,
                                      }}
                                    />
                                  </g>
                                </svg>
                              ) : elem?.types?.[0] === 'station' ? (
                                <svg
                                  width='64px'
                                  height='25px'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <path
                                      d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                      stroke='#61473a'
                                      strokeWidth={2}
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />{' '}
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  width='64px'
                                  height='25px'
                                  viewBox='-3 0 20 20'
                                  version='1.1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                  fill='#000000'>
                                  <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                  <g
                                    id='SVGRepo_tracerCarrier'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <g id='SVGRepo_iconCarrier'>
                                    {' '}
                                    <title>pin_sharp_circle [#624]</title>{' '}
                                    <desc>Created with Sketch.</desc>{' '}
                                    <defs> </defs>{' '}
                                    <g
                                      id='Page-1'
                                      stroke='none'
                                      strokeWidth={1}
                                      fill='none'
                                      fillRule='evenodd'>
                                      {' '}
                                      <g
                                        id='Dribbble-Light-Preview'
                                        transform='translate(-223.000000, -5439.000000)'
                                        fill='#61473a'>
                                        {' '}
                                        <g
                                          id='icons'
                                          transform='translate(56.000000, 160.000000)'>
                                          {' '}
                                          <path
                                            d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                            id='pin_sharp_circle-[#624]'>
                                            {' '}
                                          </path>{' '}
                                        </g>{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>
                                </svg>
                              )}

                              <p>{elem?.name.split(',')?.[0]}</p>
                              <p className='small'>{elem?.address}</p>
                            </div>
                          ))}
                        </div>
                      </OutsideClickHandler>
                    )}
                </div>
                <div className='input_custom'>
                  <img src='/img/pin.svg' alt='Pin' />
                  <input
                    type='text'
                    placeholder='Address, airport, hotel, ...'
                    onChange={(e) => {
                      setTo(e.target.value);
                      setSelectedTo(false);
                    }}
                    value={to}
                    onClick={() => setOpenToOptions(true)}
                  />
                  <p>To</p>
                  {toOptions?.length >= 1 && opentoOptions && !selectTo && (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setOpenToOptions(false);
                      }}>
                      <div className='results'>
                        {toOptions.map((elem, i) => (
                          <div
                            onClick={() => {
                              setOpenToOptions(false);
                              setTo(elem?.name.split(',')?.[0]);
                              setSelectedTo(true);
                              setToFull(elem);
                            }}>
                            {elem?.types?.[0] === 'airport' ? (
                              <svg
                                fill='#61473a'
                                height='25px'
                                width='64px'
                                version='1.1'
                                id='Layer_1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                viewBox='0 0 122.88 122.88'
                                xmlSpace='preserve'
                                className='via'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <style
                                    type='text/css'
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        ' .st0{fill-rule:evenodd;clip-rule:evenodd;} ',
                                    }}
                                  />{' '}
                                  <g>
                                    {' '}
                                    <path
                                      className='st0'
                                      d='M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75 L16.63,105.75z'
                                    />{' '}
                                  </g>{' '}
                                </g>
                              </svg>
                            ) : elem?.types?.[0] === 'hotel' ? (
                              <svg
                                fill='#000000'
                                width='64px'
                                height='25px'
                                viewBox='0 0 24 24'
                                id='hotel-3'
                                data-name='Line Color'
                                xmlns='http://www.w3.org/2000/svg'
                                className='icon line-color'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  <rect
                                    id='secondary'
                                    x={12}
                                    y={15}
                                    width={4}
                                    height={6}
                                    style={{
                                      fill: 'none',
                                      stroke: '#61473a',
                                      strokeLinecap: 'round',
                                      strokeLinejoin: 'round',
                                      strokeWidth: 2,
                                    }}
                                  />
                                  <path
                                    id='primary'
                                    d='M8,3,19.32,6.77a1,1,0,0,1,.68,1V21H8ZM4,21H8V9H5a1,1,0,0,0-1,1Zm17,0H3'
                                    style={{
                                      fill: 'none',
                                      stroke: '#61473a',
                                      strokeLinecap: 'round',
                                      strokeLinejoin: 'round',
                                      strokeWidth: 2,
                                    }}
                                  />
                                </g>
                              </svg>
                            ) : elem?.types?.[0] === 'station' ? (
                              <svg
                                width='64px'
                                height='25px'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <path
                                    d='M5 11H19M9 18L6 21M15 18L18 21M12 11V4M8 15H8.01M16 15H16.01M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.07989 5 6.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z'
                                    stroke='#61473a'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />{' '}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                width='64px'
                                height='25px'
                                viewBox='-3 0 20 20'
                                version='1.1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                fill='#000000'>
                                <g id='SVGRepo_bgCarrier' strokeWidth={0} />
                                <g
                                  id='SVGRepo_tracerCarrier'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <g id='SVGRepo_iconCarrier'>
                                  {' '}
                                  <title>pin_sharp_circle [#624]</title>{' '}
                                  <desc>Created with Sketch.</desc>{' '}
                                  <defs> </defs>{' '}
                                  <g
                                    id='Page-1'
                                    stroke='none'
                                    strokeWidth={1}
                                    fill='none'
                                    fillRule='evenodd'>
                                    {' '}
                                    <g
                                      id='Dribbble-Light-Preview'
                                      transform='translate(-223.000000, -5439.000000)'
                                      fill='#61473a'>
                                      {' '}
                                      <g
                                        id='icons'
                                        transform='translate(56.000000, 160.000000)'>
                                        {' '}
                                        <path
                                          d='M176,5286.219 C176,5287.324 175.105,5288.219 174,5288.219 C172.895,5288.219 172,5287.324 172,5286.219 C172,5285.114 172.895,5284.219 174,5284.219 C175.105,5284.219 176,5285.114 176,5286.219 M174,5296 C174,5296 169,5289 169,5286 C169,5283.243 171.243,5281 174,5281 C176.757,5281 179,5283.243 179,5286 C179,5289 174,5296 174,5296 M174,5279 C170.134,5279 167,5282.134 167,5286 C167,5289.866 174,5299 174,5299 C174,5299 181,5289.866 181,5286 C181,5282.134 177.866,5279 174,5279'
                                          id='pin_sharp_circle-[#624]'>
                                          {' '}
                                        </path>{' '}
                                      </g>{' '}
                                    </g>{' '}
                                  </g>{' '}
                                </g>
                              </svg>
                            )}

                            <p>{elem?.name.split(',')?.[0]}</p>
                            <p className='small'>{elem?.address}</p>
                          </div>
                        ))}
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </>
            )}
            <div className='input_custom date_input'>
              <img src='/img/calendar.svg' alt='Pin' />
              <input
                type='text'
                placeholder={
                  activeTab === 'with_return'
                    ? 'Departure - Return'
                    : 'Departure'
                }
                readOnly={true}
                value={
                  activeTab === 'with_return'
                    ? range && Object.keys(range)?.length >= 1
                      ? `${moment(range?.from).format('LL')} ${
                          range?.to ? `- ${moment(range?.to).format('LL')}` : ''
                        }`
                      : ''
                    : range
                    ? moment(range).format('LL')
                    : ''
                }
                onClick={() => setShowCalendar(true)}
              />
              <p>Date</p>
              {showCalendar && (
                <OutsideClickHandler
                  onOutsideClick={() => setShowCalendar(false)}>
                  <div className='calendar'>
                    <DayPicker
                      id='test'
                      mode={activeTab === 'with_return' ? 'range' : 'single'}
                      selected={range}
                      onSelect={setRange}
                      disabled={[{ before: today }, ...disabledDates]}
                    />
                  </div>
                </OutsideClickHandler>
              )}
            </div>
            {activeTab === 'with_return' ? (
              <div className='row'>
                <div className='input_custom'>
                  <img src='/img/clock.svg' alt='Pin' />
                  <input
                    type='text'
                    placeholder='15:00'
                    value={`${
                      dayjs(time).hour() < 10
                        ? `0${dayjs(time).hour()}`
                        : dayjs(time).hour()
                    } : ${
                      dayjs(time).minute() < 10
                        ? `0${dayjs(time).minute()}`
                        : dayjs(time).minute()
                    }`}
                    readOnly={true}
                    onClick={() => setShowTime(true)}
                  />
                  <p>Time</p>
                  {showTime && (
                    <OutsideClickHandler
                      onOutsideClick={() => setShowTime(false)}>
                      <div className='calendar'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticTimePicker
                            defaultValue={dayjs('2022-04-17T20:30')}
                            orientation={
                              windowWidth > 768 ? 'landscape' : 'portrait'
                            }
                            ampm={false}
                            onAccept={() => setShowTime(false)}
                            onClose={() => setShowTime(false)}
                            value={time}
                            onChange={(ti) => setTime(ti)}
                          />
                        </LocalizationProvider>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                <div className='input_custom'>
                  <img src='/img/clock.svg' alt='Pin' />
                  <input
                    type='text'
                    placeholder='15:00'
                    value={`${
                      dayjs(timeReturn).hour() < 10
                        ? `0${dayjs(timeReturn).hour()}`
                        : dayjs(timeReturn).hour()
                    } : ${
                      dayjs(timeReturn).minute() < 10
                        ? `0${dayjs(timeReturn).minute()}`
                        : dayjs(timeReturn).minute()
                    }`}
                    readOnly={true}
                    onClick={() => setShowTimeReturn(true)}
                  />
                  <p>Return Time</p>
                  {showTimeReturn && (
                    <OutsideClickHandler
                      onOutsideClick={() => setShowTimeReturn(false)}>
                      <div className='calendar calendar_mobile'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticTimePicker
                            defaultValue={dayjs('2022-04-17T15:30')}
                            orientation={
                              windowWidth > 768 ? 'landscape' : 'portrait'
                            }
                            ampm={false}
                            onAccept={() => setShowTimeReturn(false)}
                            onClose={() => setShowTimeReturn(false)}
                            value={timeReturn}
                            onChange={(ti) => setTimeReturn(ti)}
                          />
                        </LocalizationProvider>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            ) : (
              <div className='input_custom'>
                <img src='/img/clock.svg' alt='Pin' />
                <input
                  type='text'
                  placeholder='15:00'
                  value={`${
                    dayjs(time).hour() < 10
                      ? `0${dayjs(time).hour()}`
                      : dayjs(time).hour()
                  } : ${
                    dayjs(time).minute() < 10
                      ? `0${dayjs(time).minute()}`
                      : dayjs(time).minute()
                  }`}
                  readOnly={true}
                  onClick={() => setShowTime(true)}
                />
                <p>Time</p>
                {showTime && (
                  <OutsideClickHandler
                    onOutsideClick={() => setShowTime(false)}>
                    <div className='calendar'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticTimePicker
                          defaultValue={dayjs('2022-04-17T15:30')}
                          orientation={
                            windowWidth > 768 ? 'landscape' : 'portrait'
                          }
                          ampm={false}
                          onAccept={() => setShowTime(false)}
                          onClose={() => setShowTime(false)}
                          value={time}
                          onChange={(ti) => setTime(ti)}
                        />
                      </LocalizationProvider>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            )}
            <div className='input_custom'>
              <img src='/img/user.svg' alt='Pin' />
              <div>
                <img
                  src='/img/minus.svg'
                  alt='minus'
                  onClick={() => persons >= 2 && setPersons(persons - 1)}
                />
                <img
                  src='/img/plus.svg'
                  alt='plus'
                  onClick={() => persons < 7 && setPersons(persons + 1)}
                />
              </div>
              <input
                type='text'
                placeholder='1'
                maxLength={2}
                className='persons'
                value={persons}
                readOnly
              />
              <p>Persons</p>
            </div>
            <button
              className='button'
              onClick={() => {
                if (activeTab === 'with_return') {
                  validateReturn();
                } else {
                  validateOneWay();
                }
              }}>
              Book a Ride
            </button>
          </>
        ) : (
          <>
            <div className='row'>
              <div className='input_custom'>
                <svg
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                  <g
                    id='SVGRepo_tracerCarrier'
                    stroke-linecap='round'
                    stroke-linejoin='round'></g>
                  <g id='SVGRepo_iconCarrier'>
                    {' '}
                    <circle
                      cx='12'
                      cy='6'
                      r='4'
                      stroke='#3E3934'
                      stroke-width='1.5'></circle>{' '}
                    <path
                      d='M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18'
                      stroke='#3E3934'
                      stroke-width='1.5'
                      stroke-linecap='round'></path>{' '}
                  </g>
                </svg>
                <input
                  type='text'
                  placeholder='John'
                  value={customRequest?.first_name}
                  onChange={(e) => {
                    setCustomRequest({
                      ...customRequest,
                      first_name: e.target.value,
                    });
                  }}
                />
                <p>First Name</p>
              </div>
              <div className='input_custom'>
                <svg
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                  <g
                    id='SVGRepo_tracerCarrier'
                    stroke-linecap='round'
                    stroke-linejoin='round'></g>
                  <g id='SVGRepo_iconCarrier'>
                    {' '}
                    <circle
                      cx='12'
                      cy='6'
                      r='4'
                      stroke='#3E3934'
                      stroke-width='1.5'></circle>{' '}
                    <path
                      d='M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18'
                      stroke='#3E3934'
                      stroke-width='1.5'
                      stroke-linecap='round'></path>{' '}
                  </g>
                </svg>
                <input
                  type='text'
                  placeholder='Doe'
                  value={customRequest?.last_name}
                  onChange={(e) => {
                    setCustomRequest({
                      ...customRequest,
                      last_name: e.target.value,
                    });
                  }}
                />
                <p>Last Name</p>
              </div>
            </div>
            <div className='input_custom'>
              <svg
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  stroke-linecap='round'
                  stroke-linejoin='round'></g>
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M3.75 5.25L3 6V18L3.75 18.75H20.25L21 18V6L20.25 5.25H3.75ZM4.5 7.6955V17.25H19.5V7.69525L11.9999 14.5136L4.5 7.6955ZM18.3099 6.75H5.68986L11.9999 12.4864L18.3099 6.75Z'
                    fill='#3E3934'></path>{' '}
                </g>
              </svg>
              <input
                type='text'
                placeholder='john.doe@mail.com'
                value={customRequest?.email}
                onChange={(e) => {
                  setCustomRequest({
                    ...customRequest,
                    email: e.target.value,
                  });
                }}
              />
              <p>Email</p>
            </div>
            <div className='input_custom phone_input'>
              <PhoneInput
                placeholder='Enter phone number'
                value={customRequest?.phone}
                defaultCountry='IT'
                onChange={(e) => {
                  setCustomRequest({
                    ...customRequest,
                    phone: e,
                  });
                }}
              />
              <p>Phone Number</p>
            </div>
            <div className='input_custom'>
              <svg
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='message_icon'>
                <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  stroke-linecap='round'
                  stroke-linejoin='round'></g>
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <path
                    d='M8 11H8.01M12 11H12.01M16 11H16.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z'
                    stroke='#3E3934'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'></path>{' '}
                </g>
              </svg>
              <textarea
                type='text'
                rows={10}
                placeholder='Type here...'
                value={customRequest?.message}
                onChange={(e) => {
                  setCustomRequest({
                    ...customRequest,
                    message: e.target.value,
                  });
                }}
              />
              <p className='message'>Message</p>
            </div>
            <button
              className='button sent_request'
              onClick={validateCustomRequest}>
              Send Request
            </button>
          </>
        )}
      </div>
    </S.MainBlock>
  );
};

export default MainBlock;
