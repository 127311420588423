import * as S from './styles';

const Whyus = () => {
  return (
    <S.Whyus>
      <div>
        <h2>Why choose Lux Limo for your Business Trips?</h2>
        <p>
          An intimate journey you'll remember whilte your well-being is our top
          priority.
        </p>
        <div>
          <div>
            <svg
              width='40px'
              height='40px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
              <g
                id='SVGRepo_tracerCarrier'
                stroke-linecap='round'
                stroke-linejoin='round'></g>
              <g id='SVGRepo_iconCarrier'>
                {' '}
                <path
                  d='M12 17V17.5V18'
                  stroke='#fff'
                  stroke-width='1.5'
                  stroke-linecap='round'></path>{' '}
                <path
                  d='M12 6V6.5V7'
                  stroke='#fff'
                  stroke-width='1.5'
                  stroke-linecap='round'></path>{' '}
                <path
                  d='M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5'
                  stroke='#fff'
                  stroke-width='1.5'
                  stroke-linecap='round'></path>{' '}
                <path
                  d='M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7'
                  stroke='#fff'
                  stroke-width='1.5'
                  stroke-linecap='round'></path>{' '}
              </g>
            </svg>
            <div>
              <h5>Fixed Prices</h5>
              <p>
                Cash, Visa, Mastercard, PayPal.
                <br /> Deposit 30-100% Available
              </p>
            </div>
          </div>
          <div>
            <svg
              fill='#fff'
              height='40px'
              width='40px'
              version='1.1'
              id='Capa_1'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              viewBox='0 0 490 490'
              xmlSpace='preserve'>
              <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
              <g
                id='SVGRepo_tracerCarrier'
                stroke-linecap='round'
                stroke-linejoin='round'></g>
              <g id='SVGRepo_iconCarrier'>
                {' '}
                <g>
                  {' '}
                  <path d='M245,208.977c36.565,0,66.207-29.642,66.207-66.207S281.565,76.563,245,76.563s-66.208,29.642-66.208,66.207 S208.435,208.977,245,208.977z M245,91.875c28.063,0,50.895,22.831,50.895,50.895c0,28.063-22.831,50.895-50.895,50.895 c-28.064,0-50.896-22.831-50.896-50.895C194.104,114.706,216.936,91.875,245,91.875z'></path>{' '}
                  <path d='M110.796,286.815c0,61.734,0,76.049,0,76.049s130.622,0,131.072,0l-63.971-138.678 C177.898,224.186,110.796,225.082,110.796,286.815z M126.108,347.552v-60.737c0-34.228,27.182-43.702,42.448-46.323l49.385,107.06 H126.108z'></path>{' '}
                  <path d='M312.102,224.186l-63.971,138.678h131.073c0,0,0-14.315,0-76.049C379.204,225.082,312.102,224.186,312.102,224.186z M363.892,347.552h-91.834l49.422-107.137c15.242,2.507,42.412,11.825,42.412,46.4V347.552z'></path>{' '}
                  <polygon points='244.999,224.186 198.922,224.186 244.999,326.181 291.076,224.186 '></polygon>{' '}
                  <path d='M245,0C109.689,0,0,109.69,0,245s109.689,245,245,245c135.31,0,245-109.69,245-245S380.31,0,245,0z M245,474.688 C118.349,474.688,15.313,371.65,15.313,245C15.313,118.349,118.349,15.313,245,15.313c126.65,0,229.688,103.037,229.688,229.688 C474.688,371.65,371.65,474.688,245,474.688z'></path>{' '}
                </g>{' '}
              </g>
            </svg>
            <div>
              <h5>Premium & Proffesional Services</h5>
              <p>Free Wi-Fi, Drinks and Fruits</p>
            </div>
          </div>
          <div>
            <img src='/img/rolls.png' alt='Luxury Cars' />
            <div>
              <h5>Luxury Cars</h5>
              <p>Experienced Drivers and Comfortable Trips</p>
            </div>
          </div>
          <div>
            <img src='/img/seat.png' alt='seat' className='seat' />
            <div>
              <h5>Spacious and comfortable seats</h5>
              <p>Luxury car benefits</p>
            </div>
          </div>
          <div>
            <img src='/img/refund.png' alt='seat' />
            <div>
              <h5>Change or cancel your trip Free</h5>
              <p>Up to 24 hours before departure</p>
            </div>
          </div>
          <div>
            <img src='/img/ontime.png' alt='seat' />

            <div>
              <h5>Always on Time</h5>
              <p>Highest Standards and Precautions.</p>
            </div>
          </div>
        </div>
      </div>
    </S.Whyus>
  );
};

export default Whyus;
